/* eslint-disable no-underscore-dangle */

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Filters page components
import MDTypography from "components/MDTypography";
import ByTag from "./components/ByTag";
import ByRecommendation from "./components/ByRecommendation";
import BaseLayout from "../account/components/BaseLayout";
import Sidenav from "../account/settings/components/Sidenav";
import LocationFilterHeader from "./components/LocationFilterHeader";
import ByTickets from "./components/ByTickets";
import ByPrice from "./components/ByPrice";
import ByKeyword from "./components/ByKeywords";
import ByTicket from "./components/ByTicket";

const Filters = () => {
  const [selectedLocation, setSelectedLocation] = useState({});

  const handleChange = (value) => {
    if (value) {
      setSelectedLocation(value);
    } else {
      setSelectedLocation({});
    }
  };

  const concatDestinations = [
    selectedLocation?.destination ? selectedLocation.destination._id : "",
    // eslint-disable-next-line no-unsafe-optional-chaining
    ...(selectedLocation?.destinations || [])?.map((dest) => dest._id),
  ]
    .filter(Boolean)
    .join(",");

  return (
    <BaseLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item size={{ xs: 12, lg: 3 }}>
            <Sidenav title={selectedLocation?.name} />
          </Grid>
          <Grid item size={{ xs: 12, lg: 9 }}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item size={{ xs: 12 }}>
                  <LocationFilterHeader
                    handleChange={handleChange}
                    selectedLocation={selectedLocation}
                  />
                </Grid>

                {selectedLocation?._id ? (
                  <>
                    <Grid item size={{ xs: 12 }}>
                      <ByTag
                        locationId={selectedLocation?._id}
                        destination={concatDestinations}
                        filters={selectedLocation?.filters}
                      />
                    </Grid>
                    <Grid item size={{ xs: 12 }}>
                      <ByRecommendation
                        locationId={selectedLocation?._id}
                        destination={concatDestinations}
                        filters={selectedLocation?.filters}
                      />
                    </Grid>
                    <Grid item size={{ xs: 12 }}>
                      <ByTicket
                        locationId={selectedLocation?._id}
                        destination={concatDestinations}
                        filters={selectedLocation?.filters}
                      />
                    </Grid>
                    <Grid item size={{ xs: 12 }}>
                      <ByTickets
                        locationId={selectedLocation?._id}
                        filters={selectedLocation?.filters}
                      />
                    </Grid>
                    <Grid item size={{ xs: 12 }}>
                      <ByPrice
                        locationId={selectedLocation?._id}
                        filters={selectedLocation?.filters}
                      />
                    </Grid>
                    <Grid item size={{ xs: 12 }}>
                      <ByKeyword
                        locationId={selectedLocation?._id}
                        filters={selectedLocation?.filters}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item size={{ xs: 12 }}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Please Select a Location first to see your filters
                    </MDTypography>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
};

export default Filters;
