/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";
import debounce from "lodash/debounce";

const CustomOption = ({ innerProps, data }) => (
  <div {...innerProps} style={{ display: "flex", alignItems: "center", padding: "8px 12px" }}>
    <span style={{ marginRight: "5px" }}>{data?.label}</span>
    {data?.type && (
      <span style={{ fontSize: "0.75rem", opacity: 0.75 }}>({data?.type?.toLowerCase()})</span>
    )}
  </div>
);

CustomOption.propTypes = {
  innerProps: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

const CustomMultiValueLabel = ({ data }) => (
  <div style={{ display: "flex", alignItems: "center", padding: "3px 6px" }}>
    <span style={{ marginRight: "5px", fontSize: "0.870rem" }}>{data.label}</span>
    {data.type && (
      <span style={{ fontSize: "0.70rem", opacity: 0.75 }}>({data.type.toLowerCase()})</span>
    )}
  </div>
);

CustomMultiValueLabel.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const DestinationsPicker = ({ destinations, destinationsV, handleChange, setFieldValue }) => {
  const filterDestination = (data) =>
    data?.map((destinationsData) => ({
      label: destinationsData?.destinationName,
      value: destinationsData?._id,
      type: destinationsData?.destinationType,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/destinations?`;
      url += `${inputValue && `&destinationName=${inputValue}`}`;
      url += "&sort=destinationName";
      url += "&dir=asc";
      setTimeout(() => {
        // resolve();
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            const filteredData = filterDestination(result.data);
            resolve(filteredData);
          })
          .catch((error) => console.log(error.message));
      }, 1000);
    });

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((options) => {
        callback(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, 1000);

  const handleSelectChange = useCallback(
    (options) => {
      if (!options || options.length === 0) {
        // Clear both destinations and primary destination when no destinations are selected
        setFieldValue("destinations", []);
        setFieldValue("primaryDestination", ""); // Clear primary destination
        handleChange([]);
      } else if (options.length !== destinationsV?.length) {
        setFieldValue("destinations", options);
        handleChange(options);
      }
    },
    [destinationsV, setFieldValue, handleChange]
  );

  const normalizedDestinationsV = Array.isArray(destinationsV)
    ? destinationsV
    : destinationsV !== null && [destinationsV];

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {destinations.label}
      </Typography>{" "}
      <AsyncSelect
        key={
          Array.isArray(normalizedDestinationsV) &&
          normalizedDestinationsV?.map((dest) => dest.value).join(",")
        }
        isMulti
        cacheOptions
        defaultOptions
        defaultValue={normalizedDestinationsV}
        placeholder="Search for a destination"
        menuPosition="fixed"
        loadOptions={debouncedLoadOptions}
        onChange={handleSelectChange}
        // onChange={(options) => {
        //   // Handle an array of selected option
        //   if (!options || options.length === 0) {
        //     setFieldValue("destinations", []);
        //     handleChange(options);
        //   } else {
        //     setFieldValue("destinations", options);
        //     handleChange(options);
        //   }
        // }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
          Option: CustomOption, // Use the custom option component
          MultiValueLabel: CustomMultiValueLabel, // Use the custom component for selected options
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow, // Optional: Add a shadow for better visual
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor, // Maintain border color on hover
            },
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem", // Customize the selected option text size
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
        pageSize={1000} // Set pageSize to load more options
      />
    </div>
  );
};

// Setting default props
DestinationsPicker.defaultProps = {
  handleChange: () => {},
  setFieldValue: () => {},
  destinationsV: [],
  destinations: {},
  // selectedDestination: [],
};
// typechecking props for DestinationsPicker
DestinationsPicker.propTypes = {
  destinations: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  // selectedDestination: PropTypes.instanceOf(Array),
  destinationsV: PropTypes.arrayOf(
    PropTypes.shape({
      destinationName: PropTypes.string,
      id: PropTypes.string,
      _id: PropTypes.string,
    })
  ),
  // isClearable: PropTypes.bool,
};

export default DestinationsPicker;
