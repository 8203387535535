/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Divider } from "@mui/material";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";

const Review = ({ formData }) => {
  const { values } = formData;
  const [bannerInfoData, setBannerInfoData] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (values) {
      setBannerInfoData({
        bannerText: values?.title,
        position: values?.position?.label || values?.position,
        image: values?.image,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([{ id: 0, title: "Banner info", data: bannerInfoData }]);
  }, [bannerInfoData]);

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              xs={12}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList bannerDetailsPage data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// Setting default props for the Review
Review.defaultProps = {
  //   editPage: false,
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  //   editPage: PropTypes.bool,
};

export default Review;
