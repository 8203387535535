/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars

// Libraries
import { useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid2";

// Redux Actions
import {
  getAllDestinations,
  deleteDestination,
  updateDestination,
  clearDestinationsErrors,
  setDestinationsLoading,
} from "store/actions/destinationsActions";
import setAlert from "store/actions/alertActions";
import { getPdf, getCsv } from "store/actions/exportAction";

const Destinations = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.destinations.loading);
  // const destinations = useSelector((state) => state.destinations.destinations);
  const error = useSelector((state) => state.destinations.error);
  const user = useSelector((state) => state.users.user);
  const added = useSelector((state) => state.destinations.added);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearDestinationsErrors());
    }
  }, [error, setAlert]);

  useEffect(() => {
    dispatch(setDestinationsLoading());
    dispatch(getAllDestinations());
  }, [added]);

  const deleteDestinationFn = (id) => {
    dispatch(deleteDestination(id));
  };

  const updateDestinationFn = (data) => {
    dispatch(updateDestination(data));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12 }}>
          <MDBox>
            <MaterialTablePicker
              title="Destinations"
              columns={["Destination Name", "Destination Type", "Longitude ", "Latitude ", "Top"]}
              tableRef={tableRef}
              searchField="destinations"
              exportMenuField="destinations"
              asyncTable
              asyncURL={`${process.env.REACT_APP_API}/destinations?`}
              currentUrl="destinations"
              addUrl="destinations/new-destination"
              onRowClickURL="/admin/destinations"
              idSynonymName="_id"
              sortField="createdAt"
              direction="desc"
              isLoading={loading}
              editableUpdate={updateDestinationFn}
              editableDelete={deleteDestinationFn}
              updateRoleAction
              editableRole
              deleteRoleAction
              actionsName="Destination"
              //   actionsChild={actionsChildFn}
              actionsRole
              usersTable
              getPdf={(value, query) => {
                if (value) {
                  dispatch(
                    getPdf(
                      value,
                      `stats/pdf${query}&fields=destinationName,destinationType,geoLocation`,
                      "Destinations"
                    )
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for PDF export.");
                }
              }}
              getCsv={(query) => {
                if (user?.role === "admin") {
                  dispatch(
                    getCsv(
                      `stats/csv${query}&fields=destinationName,destinationType,geoLocation`,
                      "Destinations"
                    )
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for CSV export.");
                }
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default Destinations;
