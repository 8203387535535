/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */

// @mui material components
import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid2";
import Work from "@mui/icons-material/Work";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SellIcon from "@mui/icons-material/Sell";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MaterialTablePicker from "components/MaterialTablePicker";
// import Loader from "components/Loader";

import { createRef, useEffect, useState } from "react";
import setAlert from "store/actions/alertActions";
import { clearErrors, setLoading, getConciergeOrders } from "store/actions/statsActions";
import ItemsTable from "pages/reports/productionReports/all-productionReports/ProductionReportsList/ItemsTable";
import { getPdf, getCsv } from "store/actions/exportAction";
import MDTypography from "components/MDTypography";
import { Typography } from "@mui/material";
import ConciergeCard from "./components/Card";
import ConciergePromocodes from "./components/Promocodes";

const ConciergeDashboard = () => {
  const dispatch = useDispatch();
  const tableRef = createRef();

  const user = useSelector((state) => state.users.user);
  const conciergeOrders = useSelector((state) => state.stats.conciergeOrders);
  const loading = useSelector((state) => state.stats.loading);
  const error = useSelector((state) => state.stats.error);
  const [firstRun, setFirstRun] = useState(false);

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getConciergeOrders(user?._id));
  }, [firstRun]);

  useEffect(() => {
    setFirstRun(true);
  }, []);

  // ------useEffect-------
  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
    // eslint-disable-next-line
  }, [error, setAlert]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5} sx={{ marginLeft: "24px" }}>
          <Typography variant="h6" gutterBottom sx={{ fontSize: "24px", fontFamily: "bold" }}>
            Earn a $4 commission for every $100 in sales.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontSize: "24px" }}>
            Additionally, at the end of each quarter, you will earn an extra bonus when achieving
            the below numbers:
          </Typography>
          <Typography
            variant="body1"
            component="div"
            sx={{ marginLeft: "40px", fontSize: "16px", marginBottom: "10px" }}
          >
            <ul>
              <li>Total sales 1000$ = 30$ bonus</li>
              <li>Total sales 2000$ = 80$ bonus</li>
              <li>Total sales 3000$ = 150$ bonus</li>
              <li>Total sales 4000$ = 240$ bonus</li>
            </ul>
          </Typography>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexDirection: "column", marginBottom: "12px" }}
            >
              {conciergeOrders?.user?.name && (
                <MDTypography
                  display="inline"
                  variant="h4"
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  {conciergeOrders.user.name}
                </MDTypography>
              )}

              {conciergeOrders?.user &&
                (conciergeOrders.user.location || conciergeOrders.user.city) && (
                  <MDTypography
                    display="inline"
                    variant="h4"
                    textTransform="capitalize"
                    fontWeight="bold"
                  >
                    {conciergeOrders.user.location
                      ? `Location: ${conciergeOrders.user.location.name}`
                      : `Destination: ${conciergeOrders.user.destinations?.destinationName}`}
                  </MDTypography>
                )}
            </Grid>
            <Grid item size={{ xs: 12, md: 6, lg: 4 }}>
              <MDBox mb={4}>
                <ConciergeCard
                  color="success"
                  icon={<Work />}
                  title="Your Account Balance"
                  count={
                    conciergeOrders?.user?.balance || conciergeOrders?.user?.balance === 0
                      ? `${conciergeOrders?.user?.balance}$`
                      : "--"
                  }
                  title2="Commission"
                  count2={
                    conciergeOrders?.user?.commission || conciergeOrders?.user?.commission === 0
                      ? `${conciergeOrders?.user?.commission}%`
                      : "--"
                  }
                />
              </MDBox>
              <MDBox>
                <ConciergeCard
                  color="success"
                  icon={<ShoppingCartIcon />}
                  title="Number of Tickets"
                  count={conciergeOrders?.totalTicketPurchase || "--"}
                  title2="Total Sales"
                  count2={
                    conciergeOrders?.totalTicketRevenue
                      ? `${conciergeOrders?.totalTicketRevenue}$`
                      : "--"
                  }
                />
              </MDBox>
            </Grid>
            <Grid item size={{ xs: 12, md: 6 }}>
              {/* {conciergeOrders?.user ? ( */}
              <ConciergePromocodes
                key={conciergeOrders?.user?._id}
                color="success"
                icon={<SellIcon />}
                title="Promocodes"
                promoCodes={conciergeOrders?.user?.promoCodes}
                user={conciergeOrders?.user}
              />
              {/* ) : (
                <Loader />
              )} */}
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <MaterialTablePicker
                screen="ConciergeOrders"
                title="Orders"
                searchField="conciergeOrders"
                currentUrl="conciergeOrders"
                tableRef={tableRef}
                isActions={false}
                columns={[
                  "Invoice Number",
                  "Email",
                  "Quantity ",
                  "Purchase Date ",
                  // // "Booking Date",
                  "Subtotal",
                  "Discount Amount ",
                  "Order Total ",
                  "Commission ($)",
                  "Promocode",
                ]}
                isRowClickable={false}
                data={conciergeOrders?.orders}
                // eslint-disable-next-line react/no-unstable-nested-components
                detailPanel={(rowData) => (
                  <MDBox sx={{ ml: 6, mr: 4 }}>
                    <ItemsTable items={rowData.rowData.items} page="concierge" />
                  </MDBox>
                )}
                tableInside
                isFreeDownloadAction
                isLoading={loading}
                filteringOptions={false}
                isExportable={false}
                actionsRole
                actionsName="conciergeOrders"
                getPdf={(value) => {
                  if (value) {
                    dispatch(
                      getPdf(value, "conciergeOrders/createPdf?pdf=true", "Concierge Orders")
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for PDF export.");
                  }
                }}
                getCsv={(value) => {
                  if (value) {
                    dispatch(getCsv(`${process.env.REACT_APP_API}/conciergeOrders/csv?csv=true`));
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for PDF export.");
                  }
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ConciergeDashboard;
