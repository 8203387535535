/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import UploadFileIcon from "@mui/icons-material/UploadFile";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid2";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Danger from "components/Danger";

const DynamicSinglePdf = ({ values, setFieldValue, setPdfFile, pdfFile }) => {
  const [pdfFileError, setPdfFileError] = useState("");
  const [fileBase64, setFileBase64] = useState(null);

  // onchange event
  const fileType = ["application/pdf"];
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (values?.file?.length > 0) {
      setPdfFile(values?.file);
    }
  }, []);

  const handlePdfFileChange = (e) => {
    const selectedFile = e.target?.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);

      reader.onload = () => {
        const base64String = reader.result.replace(/^data:.+;base64,/, "");
        setFileBase64(base64String);
      };

      if (selectedFile && fileType.includes(selectedFile.type)) {
        setPdfFile(selectedFile);
        setPdfFileError("");
        setFieldValue("file", selectedFile);
      } else {
        setPdfFile(null);
        setPdfFileError("Please select valid pdf file!");
        setFieldValue("file", null);
      }
    } else {
      // eslint-disable-next-line no-console
      console.log("select your file");
    }
  };

  const downloadStringPdf = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      // Create a download link
      const link = document.createElement("a");

      // If filename is not provided, extract it from the URL
      link.download = filename || url.substring(url.lastIndexOf("/") + 1) || "downloaded.pdf";

      link.href = window.URL.createObjectURL(blob);

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to initiate the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  function downloadPdfFromBase64(filename) {
    const byteCharacters = atob(fileBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    URL.revokeObjectURL(url);
  }
  const handleRemoveClick = () => {
    // Clear the input element's value
    fileInputRef.current.value = "";

    // Reset pdfFile and form field value
    setFieldValue("file", "");
    setPdfFile(null);
  };

  const handleDownloadClick = () => {
    if (typeof pdfFile === "string") {
      // Handle download for string type
      downloadStringPdf(pdfFile, values?.file?.name);
    } else {
      // Handle download for base64 type
      downloadPdfFromBase64(values?.file?.name, pdfFile);
    }
  };

  return (
    <MDBox mt="20px">
      {" "}
      <Grid item size={{ xs: 9 }}>
        <div className="form-group" display="flex">
          <Grid item size={{ xs: 3 }}>
            <MDTypography variant="h6" color="dark">
              Upload Pdf
            </MDTypography>
          </Grid>
          <Grid item size={{ xs: 6 }}>
            {" "}
            <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
              <input
                type="file"
                accept=".pdf" // Specify the accepted file types if needed
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handlePdfFileChange}
              />{" "}
              <UploadFileIcon
                fontSize="medium"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  fileInputRef.current.click();
                }}
              />
            </div>
          </Grid>

          <Danger validation={pdfFileError} />
        </div>{" "}
      </Grid>
      {pdfFile && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            {fileBase64 || typeof pdfFile === "string" || typeof pdfFile === "object" ? (
              <Button
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                  },

                  paddingRight: 1,
                  paddingLeft: 0,
                }}
                variant="text"
                color="dark"
                onClick={handleDownloadClick}
              >
                {pdfFile instanceof File ? pdfFile.name : values?.file?.name || pdfFile}
              </Button>
            ) : (
              ""
            )}
          </div>
          <Tooltip title="Download PDF" arrow>
            <FileDownloadIcon
              style={{ paddingLeft: 0, cursor: "pointer" }}
              onClick={handleDownloadClick}
            />
          </Tooltip>
          {pdfFile && (
            <Tooltip title="Remove PDF" arrow>
              <IconButton
                aria-label="Delete"
                onClick={(e) => {
                  handleRemoveClick(e);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </MDBox>
  );
};

DynamicSinglePdf.defaultProps = {
  name: "",
  values: {},
  setFieldValue: () => {},
  setPdfFile: () => {},
  pdfFile: {},
};

// typechecking props for AdditionalInfo
DynamicSinglePdf.propTypes = {
  name: PropTypes.string,
  values: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func,
  setPdfFile: PropTypes.func,
  pdfFile: PropTypes.instanceOf(Object),
};

export default DynamicSinglePdf;
