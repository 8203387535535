/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";

// Material UI components
import Grid from "@mui/material/Grid2";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";
import LanguagesPicker from "components/AutoComplete/LanguagesPicker";
import DetailList from "components/DetailList";
import Danger from "components/Danger";
import InterestsCuratorPicker from "components/AutoComplete/InterestsCuratorPicker";
// import TicketsPicker from "components/AsyncSelect/TicketsPicker";
import UploadImage from "pages/content/locations/new-location/components/Sliders/UploadImage";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
// import { useEffect, useState } from "react";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import { FormControlLabel, FormGroup } from "@mui/material";
import GreenSwitch from "components/AutoComplete/GreenSwitch";

const AdditionalCuratorInfo = ({
  formData,
  detailPage,
  edit,
  selectedDestination,
  handleDestinationsChange,
}) => {
  // const dispatch = useDispatch();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { bio, languages, interests, destinations, media, type, top } = formField;
  const {
    bio: bioV,
    languages: languagesV,
    interests: interestsV,
    destinations: destinationsV,
    top: topV,
    media: mediaV,
    type: typeV,
    // tickets: ticketsV,
  } = values;
  // const { submitForm, resetForm } = useFormikContext();

  const languagesList = ["Arabic", "English", "French", "Dutch", "Spanish", "Russian"];

  // const [selectedTickets, setSelectedTickets] = useState([]);

  // const handleTicketChange = (value) => {
  //   selectedTickets?.map((val) => ({ value: val, label: val }));
  //   if (value) {
  //     setSelectedTickets(value);
  //   } else {
  //     setSelectedTickets([]);
  //   }
  // };

  const typeOptions = [
    { value: "image", label: "Image" },
    { value: "video", label: "Video" },
  ];

  // useEffect(() => {
  //   if (values?.recommendedExperiences) setSelectedTickets(values?.recommendedExperiences);
  // }, [values?.recommendedExperiences]);

  return (
    <MDBox>
      {!edit && (
        <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
            Curator info
          </MDTypography>
        </MDBox>
      )}
      {detailPage ? (
        <DetailList
          data={{
            introText: values?.bio,
            top: values?.top,
            interests: values?.interests,
            destinations: values?.destinations,
            languages: values?.languages,
            // recommendedExperiences: values?.recommendedExperiences,
            media: values?.media,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!edit && (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="photo" />
              </Grid>
            )}

            <Grid item size={{ xs: 12 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={topV}
                      name={top?.name}
                      onChange={(event) => setFieldValue("top", event.target.checked)}
                    />
                  }
                  label="Top"
                />
              </FormGroup>
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                // type={bio.type}
                label={bio.label}
                name={bio.name}
                value={bioV}
                multiline
                outlined
                rows={5}
                placeholder={bio.placeholder}
                error={errors.bio && touched.bio}
                success={bioV.length > 0 && !errors.bio}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <LanguagesPicker
                languagesList={languagesList}
                languages={languages}
                languagesV={languagesV}
                setFieldValue={setFieldValue}
                error={errors.languages && touched.languages}
                success={languagesV && !errors.languages}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <InterestsCuratorPicker
                // isObj
                interests={interests}
                interestsV={interestsV}
                setFieldValue={setFieldValue}
                error={errors.interests && touched.interests}
                success={interestsV && !errors.interests}
              />
            </Grid>

            <Grid item size={{ xs: 12 }}>
              <DestinationsPicker
                setFieldValue={setFieldValue}
                handleChange={handleDestinationsChange}
                destinations={destinations}
                destinationsV={destinationsV || selectedDestination}
              />
              <Danger validation={errors?.destinations} />
            </Grid>

            {/* <Grid item size={{ xs: 12 }}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                Recommended Experiences
              </MDTypography>
              <TicketsPicker
                isCuratorPage
                tickets={{ name: "ticket", Label: "Ticket" }}
                ticketsV={
                  selectedTickets?.length > 0 && selectedTickets[0]?._id?.length > 0
                    ? selectedTickets?.map((item) => ({
                        label: item?.title,
                        value: item?._id,
                      }))
                    : selectedTickets
                }
                setFieldValue={setFieldValue}
                handleChange={handleTicketChange}
              />
            </Grid> */}
            <Grid item size={{ xs: 12 }}>
              <DynamicRadioGroup
                groupLabel={type.label}
                name={type.name}
                value={typeV}
                options={typeOptions}
                handleChange={(event) => {
                  const selectedType = event.target.value;
                  setFieldValue(type.name, selectedType);
                  if (selectedType === "video") {
                    setFieldValue("media", ""); // Reset media to an empty string for video
                  } else {
                    setFieldValue("media", []); // Reset media to an empty array for image
                  }
                }}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid item size={{ sm: 12 }}>
              {typeV === "image" ? (
                <>
                  <UploadImage
                    key={values?.media}
                    defaultImage={
                      mediaV?.includes(".jpeg") ||
                      mediaV?.includes(".jpg") ||
                      mediaV?.includes(".png") ||
                      mediaV?.includes(".gif") ||
                      mediaV?.includes(".jfif")
                        ? mediaV
                        : mediaV?.[0]
                    }
                    setFieldValue={setFieldValue}
                    fieldName="media"
                  />
                  {errors?.media && touched?.media && <Danger validation={errors?.media} />}
                </>
              ) : (
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    type={media.type}
                    label={media.label}
                    name={media.name}
                    value={mediaV}
                    placeholder={media.placeholder}
                    error={errors.media && touched.media}
                    success={mediaV?.length > 0 && !errors.media}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// Setting default props
AdditionalCuratorInfo.defaultProps = {
  edit: false,
  selectedDestination: {},
  handleDestinationsChange: () => {},
};

// typechecking props for AdditionalCuratorInfo
AdditionalCuratorInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  selectedDestination: PropTypes.instanceOf(Object),
  handleDestinationsChange: PropTypes.func,
};

export default AdditionalCuratorInfo;
