/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";

// Material UI components
import Grid from "@mui/material/Grid2";

// Custom Components
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
import { createRef, useEffect, useState } from "react";
import { sortElement, deleteElement } from "store/actions/widgetActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddElement from "../AddElement";
import ElementDetails from "../ElementDetails";

const ElementsList = ({ selectedWidget_id }) => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const elements = useSelector((state) => state.widgets.widget.elements);
  const added = useSelector((state) => state.widgets.widget.elements.added);
  const user = useSelector((state) => state.users.user);

  const [arrayLength, setArrayLength] = useState(0);
  const [openDetails, setOpenDetails] = useState(false);

  const [elementsArray, setElementsArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState("");
  const { widget_id } = useParams();
  const widgetId = selectedWidget_id.length > 0 ? selectedWidget_id : widget_id;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  useEffect(() => {
    if (elements?.length > 0) setElementsArray(elements);
  }, []);

  useEffect(() => {
    const array = elements?.sort((a, b) => {
      if (a.order > b.order) return 1;
      return -1;
    });
    setElementsArray(array);
    if (array) {
      setArrayLength(array.length);
    }
  }, [elements]);

  //   useEffect(() => {
  //     const array = tableData?.items?.concat(tableData?.recommendations).sort((a, b) => {
  //       if (a.order > b.order) return 1;
  //       return -1;
  //     });
  //     setElementsArray(array);
  //     if (array) {
  //       setArrayLength(array.length);
  //     }
  //   }, [tableData]);

  const deleteElementFn = (oldData) => {
    dispatch(deleteElement(widgetId, oldData));
  };

  return (
    <MDBox>
      <Grid>
        <MaterialTablePicker
          key={added}
          screen="ElementsList"
          title="Elements"
          searchField="elements"
          currentUrl="elements"
          tableRef={tableRef}
          columns={["Title", "Order", "Description", "Pdfs"]}
          data={elementsArray}
          widgetId={widgetId}
          isOrder
          isSearchable={false}
          sortFn={sortElement}
          filteringOptions={false}
          actionsName="Elements"
          isExportable={false}
          editableDelete={(oldData) => deleteElementFn(oldData)}
          deleteRoleAction
          elementsTable
          isMenuDownload
          exportMenuField="elements"
          onRowClickFn={(rowData) => {
            setSelectedElement(rowData?._id);
            setOpenDetails(true);
          }}
          actionsOnClick={() => {
            handleClickOpen();
          }}
          editableRole={user.role === "admin" || user.role === "partner"}
          actionsRole={user.role === "admin" || user.role === "partner"}
        />
        <AddElement handleClose={handleClose} count={arrayLength} open={open} widgetId={widgetId} />
        <ElementDetails
          widgetId={widgetId}
          selectedElement={selectedElement}
          handleClose={handleCloseDetails}
          open={openDetails}
        />
      </Grid>
    </MDBox>
  );
};
// Setting default props
ElementsList.defaultProps = {};
// typechecking props for ElementsList
ElementsList.propTypes = {
  selectedWidget_id: PropTypes.string.isRequired,
};

export default ElementsList;
