/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { createRef, useEffect, useState } from "react";

// Mui components
import Grid from "@mui/material/Grid2";

// Store
import { useDispatch, useSelector } from "react-redux";
import {
  // setLoading,
  clearErrors,
  deleteAttraction,
  updateAttraction,
  getLocationAttractions,
  deleteSingleAttraction,
} from "store/actions/attractionActions";
import setAlert from "store/actions/alertActions";

// Custom components
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "components/Footer";
import DashboardNavbar from "components/DashboardNavbar";
import MaterialTablePicker from "components/MaterialTablePicker";
import { getUserPartnerLocations, setLoading, setLocation } from "store/actions/locationAction";
import LocationUserPartner from "components/LocationUserPartner";
import { getPdf, getCsv } from "store/actions/exportAction";
import form from "../new-attraction/schemas/form";

const Attractions = () => {
  const { location } = form.formField;

  const dispatch = useDispatch();
  const tableRef = createRef();

  const loading = useSelector((state) => state.attractions.loading);
  const error = useSelector((state) => state.attractions.error);
  const user = useSelector((state) => state.users.user);
  // const attraction = useSelector((state) => state.attractions.attraction);
  const locations = useSelector((state) => state.locations.locations);
  const locationAtt = useSelector((state) => state.locations.location);
  const added = useSelector((state) => state.attractions.added);

  const [onChangeLocationID, setOnChangeLocationID] = useState(false);
  const [locationId, setLocationId] = useState(locations[0]?._id);

  const deleteAttractionFn = (id) => {
    dispatch(deleteAttraction(id));
  };

  const updateAttractionFn = (data) => {
    const updatedData = { _id: data._id, title: data.title, city: data.city };
    dispatch(updateAttraction(updatedData));
  };
  const handleChange = (option, val) => {
    if (locationAtt) dispatch(deleteSingleAttraction());

    tableRef.current.onQueryChange();
    setOnChangeLocationID(true);
    setLocationId(val?._id);
    dispatch(setLocation(val?._id));
    dispatch(getLocationAttractions(val?._id));
  };

  useEffect(() => {
    if (user?.role === "partner") {
      dispatch(setLoading());
      dispatch(getUserPartnerLocations());
    }
  }, []);

  useEffect(() => {
    if (locations?.length > 0 && locationAtt === null) {
      setLocationId(locations[0]?._id);
    }
    // eslint-disable-next-line
  }, [locations]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  // to add use Effect for set locaton ID
  useEffect(() => {
    if (locationAtt && locationId !== locationAtt) {
      setLocationId(locationAtt);
      dispatch(setLocation(locationAtt));
      dispatch(getLocationAttractions(locationId));
    }
  }, [locationAtt, locationId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item size={{ xs: 4 }}>
          {user?.role === "partner" && locations.length > 0 && (
            <LocationUserPartner
              defaultLocationId={locationId}
              handleChange={handleChange}
              locations={locations || {}}
              location={location}
            />
          )}
        </Grid>
        <Grid item size={{ xs: 12 }}>
          {((user.role === "partner" && locationId) || user.role === "admin") && (
            <MaterialTablePicker
              key={locationId || added}
              screen="AttractionsList"
              title="Attractions"
              tableRef={tableRef}
              columns={["Title", "Destination", "Price", "TravelDesk"]}
              asyncTable
              asyncURL={
                user.role === "admin"
                  ? `${process.env.REACT_APP_API}/attractions?`
                  : onChangeLocationID
                  ? `/v2/locations/${locationId}/attractions?`
                  : locationAtt && locationAtt !== locationId
                  ? `/v2/locations/${locationAtt}/attractions?`
                  : `/v2/locations/${locationId}/attractions?`
              }
              isLoading={loading}
              searchField="attractionsList"
              exportMenuField="attractionsList"
              onRowClickURL={`/${user.role}/attractions`}
              currentUrl="attractions"
              addUrl="attractions/new-attraction"
              actionsName="attraction"
              actionsRole={user.role === "admin"}
              editableUpdate={updateAttractionFn}
              editableDelete={deleteAttractionFn}
              updateRoleAction
              deleteRoleAction
              editableRole={user.role === "admin"}
              getPdf={(value, query) => {
                if (value) {
                  if (user?.role === "partner") {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf${query}&fields=title,destinations,retail_price.formatted_iso_value,travelDesk`,
                        "Attractions"
                      )
                    );
                  } else {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf${query}&fields=title,destinations,retail_price.formatted_iso_value,travelDesk`,
                        "Attractions"
                      )
                    );
                  }
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for PDF export.");
                }
              }}
              getCsv={(query) => {
                if (user?.role === "partner") {
                  dispatch(
                    getCsv(
                      `stats/csv${query}&fields=title,destinations,retail_price.formatted_iso_value,travelDesk`,
                      "Attractions",
                      `${locationId}`
                    )
                  );
                } else if (user?.role === "admin") {
                  dispatch(
                    getCsv(
                      `stats/csv${query}&fields=title,destinations,retail_price.formatted_iso_value,travelDesk`,
                      "Attractions"
                    )
                  );
                } else {
                  // Handle the case where 'value' is undefined or empty
                  console.error("Error: No data for CSV export.");
                }
              }}
            />
          )}
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default Attractions;
