/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Danger from "components/Danger";

// NewPartner page components
import AddressLoc from "components/AddressLoc";
import DetailList from "components/DetailList";
import { useSelector } from "react-redux";
import CurrencyPicker from "components/AutoComplete/CurrencyPicker";
import FormField from "components/FormField";
import TimeZonePicker from "components/AutoComplete/TimeZonePicker";

const Address = ({
  formData,
  editPage,
  detailPage,
  selectedCurrency,
  handleCurrencyChange,
  handleTimeZoneChange,
  selectedTimeZone,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { countryCode, iataCode } = formField;
  const { countryCode: countryCodeV, iataCode: iataCodeV } = values;

  const user = useSelector((state) => state.users.user);

  const [currentLocation, setCurrentLocation] = useState({
    lat: "",
    lng: "",
  });

  const getLocation = async () => {
    try {
      await navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <MDBox>
      {!editPage ? (
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">Address</MDTypography>
        </MDBox>
      ) : (
        ""
      )}
      {detailPage && editPage ? (
        <DetailList
          data={{
            address: values?.address,
            country: values?.country,
            city: values?.city,
            lat: values?.lat,
            lng: values?.lng,
            currencyCode: values?.defaultCurrencyCode?.name,
            countryCode:
              values?.country_code?.length > 0 ? values?.country_code : values?.countryCode,
            timeZone: values?.timeZone?.name,
            iataCode: values?.iataCode,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid item sx={{ marginBottom: "24px" }}>
            <AddressLoc
              destinationPage
              formData={formData}
              setFieldValue={setFieldValue}
              currentLocation={currentLocation}
              error={user.role === "admin" && errors.address && touched.address && errors.address}
              disabled={user?.role === "partner"}
              recPage
            />
          </Grid>{" "}
          <Grid container spacing={2}>
            <Grid item size={{ xs: 6 }}>
              <MDTypography variant="h6" color="text" fontWeight="regular">
                Currency
              </MDTypography>
              <MDBox
                style={{
                  marginTop: "8px",
                  backgroundColor: "white",
                }}
              >
                <CurrencyPicker
                  currency={selectedCurrency}
                  handleSelectCurrency={handleCurrencyChange}
                  setFieldValue={setFieldValue}
                />
              </MDBox>
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <MDTypography variant="h6" color="text" fontWeight="regular">
                Time zone *
              </MDTypography>
              <MDBox
                style={{
                  marginTop: "8px",
                  backgroundColor: "white",
                }}
              >
                <TimeZonePicker
                  setFieldValue={setFieldValue}
                  timeZone={selectedTimeZone}
                  handleTimeZoneChange={handleTimeZoneChange}
                />
              </MDBox>
              {errors?.timeZone && touched?.timeZone && <Danger validation={errors?.timeZone} />}
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={2}>
            <Grid item size={{ xs: 6 }}>
              {" "}
              <FormField
                type={iataCode.type}
                label={iataCode.label}
                name={iataCode.name}
                value={iataCodeV}
                placeholder={iataCode.placeholder}
                error={errors?.iataCode && touched?.iataCode}
                success={iataCodeV?.length > 0 && !errors?.iataCode}
              />
            </Grid>{" "}
            <Grid item size={{ xs: 6 }}>
              {" "}
              <FormField
                type={countryCode.type}
                label={countryCode.label}
                name={countryCode.name}
                value={values?.country_code?.length > 0 ? values?.country_code : countryCodeV}
                placeholder={countryCode.placeholder}
                error={errors?.countryCode && touched?.countryCode}
                success={countryCodeV?.length > 0 && !errors?.countryCode}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// Setting default props
Address.defaultProps = {
  detailPage: true,
  editPage: false,
  selectedCurrency: {},
  selectedTimeZone: {},
  handleCurrencyChange: () => {},
  handleTimeZoneChange: () => {},
};

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  editPage: PropTypes.bool,
  selectedCurrency: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  handleCurrencyChange: PropTypes.func,
  handleTimeZoneChange: PropTypes.func,
  selectedTimeZone: PropTypes.instanceOf(Object),
};

export default Address;
