/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";

const ThemesPicker = ({ themes, setFieldValue, themesV, selectedTag }) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setFieldValue("themes", []);
  }, [selectedTag, setFieldValue]);

  const filterRec = (data) =>
    data.map((tagData) => ({
      label: tagData.title,
      value: tagData._id,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      let url = `${process.env.REACT_APP_API}/tags?`;
      if (selectedTag) url += `parentTagIds=${selectedTag?._id || selectedTag?.value}&`;
      if (inputValue) url += `title=${inputValue}`;
      url += "&sort=title&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result.data));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.message);
        });
    });

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {themes.label}
      </Typography>{" "}
      <AsyncSelect
        key={`${selectedTag?.value}-${themesV.length}`} // Change key to force re-render
        isMulti
        cacheOptions
        defaultOptions
        defaultValue={themesV.length > 0 ? themesV : null}
        placeholder="Search for a theme"
        menuPosition="fixed"
        loadOptions={promiseOptions}
        getOptionLabel={(option) => option.title || option?.label}
        getOptionValue={(option) => option?._id || option.value}
        onChange={(options) => {
          // Handle an array of selected options
          if (!options || options.length === 0) {
            setFieldValue("themes", []);
          } else {
            setFieldValue("themes", options);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            height: "auto", // Allow height to expand as items are added
            minHeight: "40px",
            maxHeight: "100px", // Set a maximum height
            overflowY: "auto", // Add scrolling when max height is reached
            flexWrap: "wrap", // Allow items to wrap within the picker
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
            margin: "2px", // Add spacing between items
          }),
        }}
      />
    </div>
  );
};
// Setting default props
ThemesPicker.defaultProps = {
  setFieldValue: () => {},
  themesV: [],
  selectedTag: {},
};

ThemesPicker.propTypes = {
  themes: PropTypes.instanceOf(Object).isRequired,
  selectedTag: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func,
  themesV: PropTypes.instanceOf(Array),
};

export default ThemesPicker;
