/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select";
import { capitalizeFirstLetter } from "components/helper";

const ServiceJobsCompanyPicker = ({ isDiningTab, companyV, setFieldValue, companyOptions }) => (
  <div>
    <AsyncSelect
      options={companyOptions}
      placeholder="Select a company"
      onChange={(selectedOption) => {
        if (isDiningTab) {
          setFieldValue("diningCompany", selectedOption.value.toLowerCase());
        } else {
          setFieldValue("company", selectedOption.value.toLowerCase());
        }
      }}
      value={
        companyV ? { label: capitalizeFirstLetter(companyV), value: companyV.toLowerCase() } : null
      }
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (base, state) => ({
          ...base,
          minHeight: "40px",
          borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
          boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow, // Optional: Add a shadow for better visual
          "&:hover": {
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Maintain border color on hover
          },
        }),
        input: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        menuList: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        singleValue: (base) => ({
          ...base,
          fontSize: "0.875rem", // Customize the selected option text size
        }),
      }}
    />
  </div>
);

// Setting default props
ServiceJobsCompanyPicker.defaultProps = {
  companyV: "",
  isDiningTab: false,
};

// Prop-types for ServiceJobsCompanyPicker
ServiceJobsCompanyPicker.propTypes = {
  companyV: PropTypes.string,
  isDiningTab: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  companyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ServiceJobsCompanyPicker;
