/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewPartner page components
import AddressLoc from "components/AddressLoc";
import MyMapPicker from "components/MyMapPicker";
import DetailList from "components/DetailList";
import { useSelector } from "react-redux";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
import Danger from "components/Danger";
import PrimaryDestinationPicker from "components/AutoComplete/PrimaryDestinationPicker";

const Address = ({
  formData,
  detailPage,
  editPage,
  selectedLocation,
  handleLocationChange,
  handlePrimaryDestinationChange,
  selectedPrimaryDestination,
  handleSecondaryDestinationChange,
  selectedSecondaryDestination,
  setSelectedPrimaryDestination,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { primaryDestination, destinations } = formField;
  const { primaryDestination: primaryDestinationV, destinations: destinationsV } = values;

  const user = useSelector((state) => state.users.user);
  const locations = useSelector((state) => state.locations.locations);
  const locationPartnerId = useSelector((state) => state.locations.location);
  const locationPartner = locations
    .map((loc) => (loc._id === locationPartnerId ? loc : null))
    .find((loc) => loc !== null);

  useEffect(() => {
    if (user?.role === "partner") {
      if (locationPartnerId) {
        setFieldValue("location", locationPartner);
      } else setFieldValue("location", locations[0]);
    }
  }, [locationPartnerId]);

  const [currentLocation, setCurrentLocation] = useState({
    lat: "",
    lng: "",
  });

  const getLocation = async () => {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } catch (error) {
      // handle error
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (destinationsV?.length === 0 && selectedSecondaryDestination?.length === 0) {
      setSelectedPrimaryDestination(null);
      setFieldValue("primaryDestination", "");
    }
  }, [destinationsV, selectedSecondaryDestination]);

  return (
    <MDBox>
      {!editPage ? (
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">Address</MDTypography>
        </MDBox>
      ) : (
        ""
      )}
      {detailPage && editPage ? (
        <>
          <DetailList
            data={{
              primaryDestination:
                selectedPrimaryDestination && Object.keys(selectedPrimaryDestination).length > 0
                  ? selectedPrimaryDestination?.label
                  : destinationsV.filter(
                      (destination) =>
                        destination.label === values?.primaryDestination?.destinationName
                    ),
              destinations:
                selectedSecondaryDestination &&
                Object.keys(selectedSecondaryDestination).length > 0 &&
                values?.destinations &&
                values?.destinations.length > 0
                  ? values?.destinations
                      .map((option) => option.label || option?.destinationName)
                      .join(", ")
                  : values.destinations?.map((destination) => destination?.label),

              location: values.location || locationPartner?.name || "",
              address: values?.address,
              country: values?.country,
              city: values?.city,
              lat: values?.lat,
              lng: values?.lng,
            }}
          />
          <div style={{ marginTop: "16px" }}>
            <MyMapPicker
              lat={values.lat !== "" ? values.lat?.toString() : currentLocation.lat.toString()}
              lng={values.lng !== "" ? values.lng?.toString() : currentLocation.lng.toString()}
              setFieldValue={setFieldValue}
              disabled
            />
          </div>
        </>
      ) : (
        <MDBox mt={1.625}>
          <Grid item size={{ xs: 12 }} sx={{ mt: "16px" }}>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <DestinationsPicker
                setFieldValue={setFieldValue}
                handleChange={handleSecondaryDestinationChange}
                destinations={destinations}
                destinationsV={destinationsV || selectedSecondaryDestination}
                setSelectedPrimaryDestination={setSelectedPrimaryDestination}
              />
            </div>
            <Danger validation={errors?.destinations} />
          </Grid>

          <Grid item size={{ xs: 12 }} sx={{ mt: "16px" }}>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PrimaryDestinationPicker
                setFieldValue={setFieldValue}
                handleChange={handlePrimaryDestinationChange}
                selectedDestination={
                  destinationsV.length === 0
                    ? null // If no destinations are selected, clear selectedPrimaryDestination
                    : selectedPrimaryDestination &&
                      Object.keys(selectedPrimaryDestination).length > 0 &&
                      destinationsV.some(
                        (destination) => destination.label === selectedPrimaryDestination.label
                      )
                    ? selectedPrimaryDestination // Display selectedPrimaryDestination if it's not empty and it's included in destinationsV
                    : destinationsV.some(
                        (destination) => destination.label === primaryDestinationV?.destinationName
                      )
                    ? primaryDestinationV // Display primaryDestinationV if selectedPrimaryDestination is empty or not included in destinationsV
                    : null // Clear selectedPrimaryDestination if it's not in destinationsV but primaryDestinationV is also not in destinationsV
                }
                destination={primaryDestination}
                destinationsObj={destinationsV || selectedSecondaryDestination}
              />
            </div>
          </Grid>
          <Grid item sx={{ marginBottom: "24px" }}>
            <AddressLoc
              formData={formData}
              setFieldValue={setFieldValue}
              currentLocation={currentLocation}
              selectedLocation={selectedLocation}
              handleLocationChange={handleLocationChange}
              error={user.role === "admin" && errors.address && touched.address && errors.address}
              disabled={user?.role === "partner"}
              recPage
            />
          </Grid>
          <Grid item size={{ xs: 12 }}>
            <MyMapPicker
              lat={values.lat !== "" ? values.lat?.toString() : currentLocation.lat.toString()}
              lng={values.lng !== "" ? values.lng?.toString() : currentLocation.lng.toString()}
              setFieldValue={setFieldValue}
            />
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// Setting default props
Address.defaultProps = {
  detailPage: true,
  editPage: false,
  selectedLocation: {},
  handleLocationChange: () => {},
  selectedPrimaryDestination: {},
  handlePrimaryDestinationChange: () => {},
  handleSecondaryDestinationChange: () => {},
  selectedSecondaryDestination: {},
  setSelectedPrimaryDestination: () => {},
};

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  editPage: PropTypes.bool,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
  handlePrimaryDestinationChange: PropTypes.func,
  handleSecondaryDestinationChange: PropTypes.func,
  selectedSecondaryDestination: PropTypes.instanceOf(Object),
  setSelectedPrimaryDestination: PropTypes.func,
};

export default Address;
