/* eslint-disable no-underscore-dangle */

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components

// Filters page components
import ByTag from "pages/filters/components/ByTag";
import ByRecommendation from "pages/filters/components/ByRecommendation";
import ByTickets from "pages/filters/components/ByTickets";
import ByPrice from "pages/filters/components/ByPrice";
import ByKeyword from "pages/filters/components/ByKeywords";
import ByTicket from "pages/filters/components/ByTicket";

const LocationFilters = ({ id, filters, selectedLocation }) => {
  const concatDestinations = [
    selectedLocation?.destination ? selectedLocation.destination._id : "",
    // eslint-disable-next-line no-unsafe-optional-chaining
    ...(selectedLocation?.destinations || [])?.map((dest) => dest._id),
  ]
    .filter(Boolean)
    .join(",");

  return (
    <Grid container spacing={3}>
      <Grid item size={{ xs: 12 }}>
        <ByTag locationId={id} destination={concatDestinations} filters={filters} />
      </Grid>
      <Grid item size={{ xs: 12 }}>
        <ByRecommendation locationId={id} destination={concatDestinations} filters={filters} />
      </Grid>
      <Grid item size={{ xs: 12 }}>
        <ByTicket
          locationId={id}
          destination={concatDestinations}
          filters={selectedLocation?.filters}
        />
      </Grid>
      <Grid item size={{ xs: 12 }}>
        <ByTickets locationId={id} filters={filters} destination={concatDestinations} />
      </Grid>
      <Grid item size={{ xs: 12 }}>
        <ByPrice locationId={id} filters={filters} />
      </Grid>
      <Grid item size={{ xs: 12 }}>
        <ByKeyword locationId={id} filters={filters} />
      </Grid>
    </Grid>
  );
};

// Setting default props
LocationFilters.defaultProps = {
  id: "",
  filters: {},
  selectedLocation: {},
};

// typechecking props for LocationFilters
LocationFilters.propTypes = {
  id: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
  selectedLocation: PropTypes.instanceOf(Object),
};

export default LocationFilters;
