/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import DetailList from "components/DetailList";
import OpeningHours from "components/OpeningHours";
import Danger from "components/Danger";
import MDDatePicker from "components/MDDatePicker";
import { dateFormatWithDash } from "components/helper";
import { useEffect, useState } from "react";
import { FormControlLabel, FormGroup } from "@mui/material";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import { v4 as uuidv4 } from "uuid"; // Import UUID library

const DateAndTimeInfo = ({ formData, editPage, detailPage }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { openHours, startDate, endDate, openEnded } = formField;
  const {
    startDate: startDateV,
    endDate: endDateV,
    openHours: openHoursV,
    openEnded: openEndedV,
    source: sourceV,
  } = values;
  const startDateKey = uuidv4();

  // State
  const [startDateProm, setStartDateProm] = useState(undefined);
  const [endDateProm, setEndDateProm] = useState(undefined);
  // UseEffect
  useEffect(() => {
    if (formData?.values?.startDate) {
      setStartDateProm(dateFormatWithDash(formData?.values?.startDate));
    }
    if (openEndedV) {
      setEndDateProm(undefined);
      setFieldValue("endDate", "");
      setStartDateProm(dateFormatWithDash(formData?.values?.startDate));
    } else if (formData?.values?.endDate) {
      setEndDateProm(dateFormatWithDash(formData?.values?.endDate));
    }
  }, [formData?.values?.startDate, formData?.values?.endDate, openEndedV]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Date And Time Info</MDTypography> : ""}
      </MDBox>

      {detailPage && editPage ? (
        <DetailList
          data={{
            openEnded: values?.openEnded,
            openHours: values?.openHours,
            startDate: values?.startDate?.length > 0 && dateFormatWithDash(values?.startDate),
            endDate:
              !values?.openEnded &&
              values?.endDate?.length > 0 &&
              dateFormatWithDash(values?.endDate),
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          {" "}
          <Grid item size={{ xs: 6 }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={openEndedV}
                    name={openEnded.name}
                    onChange={(event) => setFieldValue("openEnded", event.target.checked)}
                  />
                }
                label="Open Ended"
              />
            </FormGroup>
          </Grid>
          {sourceV !== "reserve-out" && (
            <Grid container spacing={3}>
              <Grid item size={{ xs: 6 }}>
                <MDDatePicker
                  key={startDateKey}
                  input={{
                    type: startDate.type,
                    label: startDate.label,
                    name: startDate.name,
                    variant: "standard",
                    width: "100%",
                    value: startDateProm,
                  }}
                  onChange={(event, val) => {
                    setFieldValue("startDate", val);
                    setStartDateProm(val);
                  }}
                  inputFormat="dd/MM/yyyy"
                  // onChange={(e) => handleStartDate(e)}
                  // renderInput={(params) => <TextField {...params} />}
                  error={errors.startDate && touched.startDate}
                  success={startDateV?.length > 0 && !errors.startDate}
                  options={{
                    minDate: new Date(),
                    // dateFormat: "d-m-Y",
                  }}
                />
              </Grid>

              {!values?.openEnded && (
                <Grid item size={{ xs: 6 }}>
                  <MDDatePicker
                    key={endDateProm?.length}
                    input={{
                      type: endDate.type,
                      label: endDate.label,
                      name: endDate.name,
                      variant: "standard",
                      width: "100%",
                      value: endDateProm,
                    }}
                    onChange={(event, val) => {
                      setFieldValue("endDate", val);
                      setEndDateProm(val);
                    }}
                    //   onChange={(e) => handleEndDate(e)}
                    // renderInput={(params) => <TextField {...params} />}
                    error={errors.startDate && touched.startDate}
                    success={endDateV?.length > 0 && !errors.endDate}
                    options={{
                      minDate: values.startDate || new Date(), // Set minDate to startDate or current date if startDate is not set

                      // dateFormat: "d-m-Y",
                    }}
                  />
                </Grid>
              )}

              <Grid item size={{ xs: 12 }}>
                <OpeningHours
                  key={openHours}
                  setFieldValue={setFieldValue}
                  openHours={openHoursV}
                />
                {openHoursV?.length === 0 ? (
                  <MDBox sx={{ marginTop: "30px" }}>
                    <MDTypography variant="h5">No Days Found!</MDTypography>
                    {errors?.openHours && touched?.openHours && (
                      <Danger validation={errors?.openHours} />
                    )}
                  </MDBox>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          )}
        </MDBox>
      )}
    </MDBox>
  );
};
DateAndTimeInfo.defaultProps = {
  editPage: false,
  detailPage: true,
};
// typechecking props for DateAndTimeInfo
DateAndTimeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default DateAndTimeInfo;
