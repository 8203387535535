/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";

// Material UI components
import Grid from "@mui/material/Grid2";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PartnerPicker from "components/AutoComplete/PartnerPicker";
import PartnerLocationPicker from "components/AutoComplete/PartnerLocationPicker";
import DetailList from "components/DetailList";
import { useSelector } from "react-redux";

const AdditionalPartnerInfo = ({
  formData,
  partnerObj,
  detailPage,
  edit,
  selectedPartner,
  handlePartnerChange,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { partner, locations } = formField;
  const { partner: partnerV, locations: locationsV } = values;
  const partners = useSelector((state) => state.partners.partners);
  const selectedPartners = partners.filter((part) => part.id === values.partner);
  const selectedPartnerNames = selectedPartners.map((part) => part.name);
  // Selector
  const user = useSelector((state) => state.users.user);
  return (
    <MDBox>
      {!edit && (
        <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
            Partner info
          </MDTypography>
        </MDBox>
      )}
      {detailPage ? (
        <DetailList
          data={{
            partner: values?.partner
              ? values?.partner
              : selectedPartner?.name
              ? selectedPartner?.name
              : selectedPartnerNames || "",
            locations: values?.locations && values?.locations,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <PartnerPicker
                partner={partner}
                handleChange={handlePartnerChange}
                setFieldValue={setFieldValue}
                selectedPartner={selectedPartner || partnerObj || {}}
                error={errors.partner && touched.partner && errors.partner?._id}
                success={partnerV?.name && !errors.partner && errors.partner?._id}
                disabled={user.role === "partner"}
              />
            </Grid>
            {partnerV ? (
              <Grid item size={{ xs: 12, sm: 6 }}>
                <PartnerLocationPicker
                  partnerId={partnerV?._id || partnerV}
                  locations={locations}
                  locationsV={locationsV}
                  setFieldValue={setFieldValue}
                  error={errors.locations && touched.locations}
                  success={locationsV && !errors.locations}
                  disabled={user.role === "partner"}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// Setting default props
AdditionalPartnerInfo.defaultProps = {
  partnerObj: {},
  edit: false,
  selectedPartner: {},
  handlePartnerChange: () => {},
};

// typechecking props for AdditionalCuratorInfo
AdditionalPartnerInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  partnerObj: PropTypes.instanceOf(Object),
  detailPage: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  selectedPartner: PropTypes.instanceOf(Object),
  handlePartnerChange: PropTypes.func,
};

export default AdditionalPartnerInfo;
