/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DetailList from "components/DetailList";
import FormField from "components/FormField";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import { FormControlLabel, FormGroup } from "@mui/material";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import MDDatePicker from "components/MDDatePicker";
import { useEffect, useState } from "react";
import { dateFormatWithDash } from "components/helper";

const AdditionalInfo = ({ formData, editPage, detailPage }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    type,
    value,
    maxUsage,
    limit,
    quantityPerDay,
    quantity,
    openEnded,
    dayRestriction,
    startDate,
    endDate,
  } = formField;

  // State
  const [startDateProm, setStartDateProm] = useState("");
  const [endDateProm, setEndDateProm] = useState("");
  // UseEffect
  useEffect(() => {
    if (formData?.values?.startDate) {
      setStartDateProm(dateFormatWithDash(formData?.values?.startDate));
    }
    if (formData?.values?.endDate) {
      setEndDateProm(dateFormatWithDash(formData?.values?.endDate));
    }
  }, [formData?.values?.startDate, formData?.values?.endDate]);

  const {
    type: typeV,
    value: valueV,
    maxUsage: maxUsageV,
    limit: limitV,
    quantityPerDay: quantityPerDayV,
    quantity: quantityV,
    openEnded: openEndedV,
    dayRestriction: dayRestrictionV,
    startDate: startDateV,
    endDate: endDateV,
  } = values;
  const typeOptions = [
    { value: "percentage", label: "Percentage" },
    { value: "amount", label: "Fixed Amount" },
  ];

  const quantityOptions = [
    { value: "per_day", label: "Per Day" },
    { value: "no_day_restriction", label: "No day Restriction" },
  ];

  const radioButtons = [
    {
      id: 1,
      name: type?.name,
      // label: type?.label,
      value: typeV,
      options: typeOptions,
    },
  ];

  const dayButtons = [
    {
      id: 2,
      name: dayRestriction?.name,
      // label: dayRestriction?.label,
      value: dayRestrictionV,
      options: quantityOptions,
    },
  ];

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Additional info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            type: values?.type,
            value: values?.value,
            maxUsage: values?.maxUsage,
            limit: values?.limit,
            quantityPerDay: limitV && dayRestrictionV === "per_day" && values?.quantityPerDay,
            quantity: limitV && dayRestrictionV === "no_day_restriction" && values?.quantity,
            openEnded: values?.openEnded,
            experienceX: values?.experienceX,
            startDate: values?.startDate,
            endDate: values?.endDate,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={1}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item size={{ xs: 12 }}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={openEndedV}
                        name={openEnded.name}
                        onChange={(event) => setFieldValue("openEnded", event.target.checked)}
                      />
                    }
                    label="Open Ended"
                  />
                </FormGroup>
              </Grid>
              <Grid item size={{ xs: 6 }}>
                <MDDatePicker
                  input={{
                    type: startDate.type,
                    label: startDate.label,
                    name: startDate.name,
                    variant: "standard",
                    width: "100%",
                    value: startDateProm,
                  }}
                  onChange={(event, val) => {
                    setFieldValue("startDate", val);
                    setStartDateProm(val);
                  }}
                  inputFormat="dd/MM/yyyy"
                  // onChange={(e) => handleStartDate(e)}
                  // renderInput={(params) => <TextField {...params} />}
                  error={errors.startDate && touched.startDate}
                  success={startDateV?.length > 0 && !errors.startDate}
                  options={{
                    minDate: new Date(),
                    // dateFormat: "d-m-Y",
                  }}
                />
              </Grid>
              {!values?.openEnded && (
                <Grid item size={{ xs: 6 }}>
                  <MDDatePicker
                    input={{
                      type: endDate.type,
                      label: endDate.label,
                      name: endDate.name,
                      variant: "standard",
                      width: "100%",
                      value: endDateProm,
                    }}
                    onChange={(event, val) => {
                      setFieldValue("endDate", val);
                      setEndDateProm(val);
                    }}
                    //   onChange={(e) => handleEndDate(e)}
                    // renderInput={(params) => <TextField {...params} />}
                    error={errors.startDate && touched.startDate}
                    success={endDateV?.length > 0 && !errors.endDate}
                    options={{
                      minDate: values.startDate || new Date(), // Set minDate to startDate or current date if startDate is not set

                      // dateFormat: "d-m-Y",
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {radioButtons?.map((item) => (
              <Grid item size={{ xs: 6 }} key={item.id} style={{ display: "inline-block" }}>
                <DynamicRadioGroup
                  groupLabel={item.value.label}
                  name={item.name}
                  value={item.value}
                  options={item.options}
                  setFieldValue={setFieldValue}
                  style={{ display: "inline-block" }}
                />
              </Grid>
            ))}
            <Grid item size={{ xs: 6 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormField
                  type={value.type}
                  label={value.label}
                  name={value.name}
                  value={valueV}
                  placeholder={value.placeholder}
                />
                {typeV === "percentage" ? (
                  <label style={{ marginLeft: "5px" }}>%</label>
                ) : (
                  <label style={{ marginLeft: "5px" }}>$</label>
                )}
              </div>
            </Grid>

            <Grid item size={{ xs: 12 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={limitV}
                      name={limit.name}
                      onChange={(event) => setFieldValue("limit", event.target.checked)}
                    />
                  }
                  label="Limit"
                />
              </FormGroup>
            </Grid>
            {dayButtons?.map((item) => (
              <>
                <Grid item size={{ xs: 3 }}>
                  <Grid
                    item
                    size={{ xs: 12 }}
                    key={item.id}
                    style={{ display: "inline-block", flexDirection: "row" }}
                  >
                    <DynamicRadioGroup
                      groupLabel={item.label}
                      name={item.name}
                      value={item.value}
                      options={item.options}
                      setFieldValue={setFieldValue}
                      style={{ display: "inline-block" }}
                      disabled={!limitV}
                    />{" "}
                  </Grid>{" "}
                </Grid>{" "}
                <Grid item size={{ xs: 6 }}>
                  {" "}
                  <Grid item size={{ xs: 6 }}>
                    <FormField
                      type={quantityPerDay.type}
                      label={quantityPerDay.label}
                      name={quantityPerDay.name}
                      value={quantityPerDayV}
                      placeholder={quantityPerDay.placeholder}
                      disabled={!limitV || (limitV && dayRestrictionV === "no_day_restriction")}
                    />
                  </Grid>
                  <Grid item size={{ xs: 6 }}>
                    <FormField
                      type={quantity.type}
                      label={quantity.label}
                      name={quantity.name}
                      value={quantityV}
                      placeholder={quantity.placeholder}
                      disabled={!limitV || (limitV && dayRestrictionV === "per_day")}
                    />
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid item size={{ xs: 12 }}>
            {" "}
            <Grid item size={{ xs: 6 }}>
              <FormField
                type={maxUsage.type}
                label={maxUsage.label}
                name={maxUsage.name}
                value={maxUsageV}
                placeholder={maxUsage.placeholder}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};
AdditionalInfo.defaultProps = {
  editPage: false,
  detailPage: true,
};
// typechecking props for AdditionalInfo
AdditionalInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default AdditionalInfo;
