/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Material UI components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TranslateIcon from "@mui/icons-material/Translate";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import { useHandleError } from "components/helper";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import {
  updateMenu,
  clearErrors,
  getOneMenu,
  updateMenuTranslations,
  setLoading,
} from "store/actions/menuActions";
import setAlert from "store/actions/alertActions";
// Schemas
import ViewEditActions from "components/DetailList/ViewEditActions";
import DynamicTranslate from "components/DynamicTranslate";
import form from "../new-menu/schema/form";
import initialValues from "../new-menu/schema/initialValues";
import MenuDetail from "./components/MenuDetail";
import validations from "./schemas/validations";

const EditMenu = () => {
  const { formId, formField } = form;
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const loading = useSelector((state) => state.menus.loading);
  const menu = useSelector((state) => state.menus.menu);
  const error = useSelector((state) => state.menus.error);
  const added = useSelector((state) => state.menus.added);
  const user = useSelector((state) => state.users.user);
  const menuType = useSelector((state) => state.menus.menuType);

  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [image, _setImage] = useState(menu?.logo);
  const [informationPage, setInformationPage] = useState(true);
  const [filterPage, setFilterPage] = useState(true);
  const [translateItems, setTranslateItems] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [initialState, setInitialState] = useState(initialValues);
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const { menu_id } = useParams();

  const detailTabs = [
    { id: 1, title: "DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];

  useEffect(() => {
    if (menu) {
      const arTranslation = menu?.translations?.ar;
      const arr = [
        {
          id: 1,
          name: "title",
          title: "Title",
          value: arTranslation?.title || "",
          default: "",
          label: "Title",
        },
      ];
      setTranslateItems(arr);
    }
  }, [menu]);

  useEffect(() => {
    if (menu_id) {
      dispatch(getOneMenu(menu_id));
    }
  }, [menu_id, added]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (menu?.logo) {
      _setImage(menu?.logo);
    }
  }, [menu?.logo]);
  useEffect(() => {
    if (menu) {
      const manipulated = {};
      manipulated.title = menu?.title ? menu?.title : "";
      manipulated.logo = menu?.logo ? menu?.logo : "";
      manipulated.icon = menu?.icon ? menu?.icon : "";
      manipulated.option = menu?.option ? menu?.option : "";
      manipulated.type = menu?.type ? menu?.type : "global";
      manipulated.filterBy = menu?.tickets?.length > 0 ? "tickets" : "tags";
      manipulated.destinations = menu?.destinations
        ? menu?.destinations?.map((destination) => ({
            label: destination?.destinationName,
            value: destination?._id,
            type: destination?.destinationType,
          }))
        : [];
      manipulated.locations = menu?.locations ? menu?.locations : [];
      manipulated.tags = menu?.tags
        ? menu?.tags?.map((tag) => ({
            label: tag?.title,
            value: tag?._id,
          }))
        : [];
      manipulated.tickets = menu?.tickets
        ? menu?.tickets?.map((ticket) => ({
            label: ticket?.title,
            value: ticket?._id,
          }))
        : [];
      manipulated.url = menu?.url ? menu?.url : "";
      manipulated.hidden = menu?.hidden ? menu?.hidden : false;
      manipulated.top = menu?.top ? menu?.top : false;
      manipulated.expandable = menu?.expandable ? menu?.expandable : false;
      manipulated.filters = menu?.filters ? menu?.filters : [];
      manipulated.images = menu?.icon ? menu?.icon : "";
      manipulated._id = menu?._id;
      const manipulatedDestinations = menu?.destinations
        ? menu.destinations.map((destination) => ({
            label: destination?.destinationName,
            value: destination?._id,
            type: destination?.destinationType,
          }))
        : [];
      setSelectedDestinations(manipulatedDestinations);
      setSelectedLocations(menu?.locations);
      setSelectedTags(menu?.tags);
      setSelectedTickets(menu?.tickets);

      setInitialState(manipulated);
    }
  }, [menu]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (values) => {
    const updatedValues = { ...values };
    await sleep(1000);
    dispatch(setLoading());
    if (updatedValues?.title === menu?.title) delete updatedValues?.title;
    if (updatedValues?.images === menu?.icon) delete updatedValues?.images;
    if (updatedValues?.logo === menu?.logo) delete updatedValues?.logo;

    // eslint-disable-next-line no-alert
    dispatch(updateMenu(updatedValues, values?.logo));
    setInformationPage(true);
    setFilterPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleDestinationsChange = (val) => {
    if (val) {
      setSelectedDestinations(val);
    } else {
      setSelectedDestinations([]);
    }
  };

  const handleLocationsChange = (val) => {
    if (val) {
      setSelectedLocations(val);
    } else {
      setSelectedLocations([]);
    }
  };

  const handleTagsChange = (val) => {
    if (val) {
      setSelectedTags(val);
    } else {
      setSelectedTags([]);
    }
  };

  const handleTicketChange = (val) => {
    if (val) {
      setSelectedTickets(val);
    } else {
      setSelectedTickets([]);
    }
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/menus`;
      // navigate(url);
      navigate(url, { state: { menuType: menuType.type } });
    }
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneMenu(menu._id));
    }
  };

  const handleSubmitTranslate = (values) => {
    dispatch(updateMenuTranslations(values, menu?._id));
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={menu?._id}
          />
        );

      default:
        return null;
    }
  };

  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "information":
        detailPage = informationPage;
        setDetailPage = setInformationPage;
        break;
      default:
        detailPage = filterPage;
        setDetailPage = setFilterPage;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "information") {
        setInformationPage(false);
        setFilterPage(true);
      }
      if (type === "filter") {
        setInformationPage(true);
        setFilterPage(false);
      }
      setImgDetailPage(true);
    };

    return (
      <MenuDetail
        type={type}
        formData={formData}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        handleDestinationsChange={handleDestinationsChange}
        selectedDestinations={selectedDestinations}
        handleLocationsChange={handleLocationsChange}
        selectedLocations={selectedLocations}
        handleTagsChange={handleTagsChange}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        setSelectedLocations={setSelectedLocations}
        setSelectedDestinations={setSelectedDestinations}
        handleTicketChange={handleTicketChange}
        selectedTickets={selectedTickets}
        setSelectedTickets={setSelectedTickets}
      />
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={validations[0]}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      user={menu}
                      image={image}
                      imgDetailPage={imgDetailPage}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      imageType="logo"
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setInformationPage(true);
                            setFilterPage(true);
                          }}
                          handleClose={() => {
                            _setImage(menu?.logo);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["information", "filter"].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item size={{ xs: 12 }}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(item, {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      resetForm,
                                    })}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditMenu;
