/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TokenIcon from "@mui/icons-material/Token";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import setAlert from "store/actions/alertActions";
// Schemas
import CardsList from "components/CardsList";
import { clearErrors as clearCityErrors } from "store/actions/cityActions";
import { resetFormToInitialState } from "components/helper";
import {
  getOneVenue,
  updateVenue,
  clearErrors,
  setLoading,
  getVenueTicket,
  setItemsLoading,
} from "store/actions/venuesActions";
// import { getItems } from "store/actions/itemAction";
import validations from "./schemas/validations";
import form from "../new-venue/schemas/form";
import initialValues from "../new-venue/schemas/initialValues";
import VenueDetails from "./components/VenueDetails";

const EditVenue = () => {
  const { formId, formField } = form;
  const { venue_id } = useParams();

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const venue = useSelector((state) => state.venues.venue);
  const error = useSelector((state) => state.venues.error);
  const added = useSelector((state) => state.venues.added);
  const user = useSelector((state) => state.users.user);
  const items = useSelector((state) => state?.items?.items);
  const itemsLoading = useSelector((state) => state?.items?.loading);
  // state
  const [initialState, setInitialState] = useState(initialValues);
  const [image, _setImage] = useState(venue?.thumbnailHiResURL);
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [formRefVal, setFormRefVal] = useState(null);
  const [venueDetailPage, setVenueDetailPage] = useState(true);
  const [addressDetailPage, setAddressDetailPage] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [selectedPrimaryDestination, setSelectedPrimaryDestination] = useState(null);
  const detailTabs = [
    { id: 1, title: "VENUE DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "TICKETS", iconComponent: TokenIcon },
  ];

  const currentValidation = !venueDetailPage
    ? validations[0]
    : !addressDetailPage
    ? validations[1]
    : undefined;

  useEffect(() => {
    if (formRefVal?.city) {
      dispatch(clearCityErrors());
    }
  }, [formRefVal, formRefVal?.city]);
  // useEffect
  useEffect(() => {
    if (venue_id) {
      dispatch(getOneVenue(venue_id));
    }
  }, [venue_id, added]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
      // Reset the form to its initial state
      resetFormToInitialState(formRef, initialState);
    }
  }, [error, setAlert, clearErrors]);

  useEffect(() => {
    if (venue?.thumbnailHiResURL) {
      _setImage(venue?.thumbnailHiResURL);
    }
  }, [venue?.thumbnailHiResURL]);
  // We cant use the venue as is because of address picker and map picker
  useEffect(() => {
    const fetchData = async () => {
      if (venue) {
        const manipulated = {};
        manipulated.title = venue?.title ? venue?.title : "";
        manipulated.fomoMessage = venue?.fomoMessage ? venue?.fomoMessage : "";

        manipulated.address = venue?.geoLocationData?.address;
        manipulated.city = venue?.geoLocationData?.city
          ? venue.geoLocationData?.city
          : venue.city || "";
        manipulated.region = venue.geoLocationData?.region ? venue.geoLocationData?.region : "";
        manipulated.country = venue.geoLocationData?.country ? venue.geoLocationData?.country : "";
        manipulated.lat = venue.geoLocation?.coordinates[1]
          ? venue.geoLocation?.coordinates[1]
          : "";
        manipulated.lng = venue.geoLocation?.coordinates[0]
          ? venue.geoLocation?.coordinates[0]
          : "";
        manipulated.primaryDestinationId = venue?.primaryDestinationId
          ? venue?.primaryDestinationId
          : "";
        manipulated.top = venue?.top ? venue?.top : false;
        manipulated._id = venue?._id;
        _setImage(venue?.thumbnailHiResURL);

        setInitialState(manipulated);
      }
    };
    fetchData();
  }, [venue]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    // const updatedValues = { ...values };
    const {
      title = "",
      fomoMessage = "",
      thumbnailHiResURL = "",
      top = false,
      city = "",
      address = "",
      region = "",
      country = "",
      lat = "",
      lng = "",
      primaryDestinationId = "",
    } = values;

    const baseValues = {
      _id: values?._id,
      title,
      fomoMessage,
      top,
      thumbnailHiResURL,
      primaryDestinationId,
      address,
      city,
      country,
      region,
      lat,
      lng,
    };
    const updatedValues = { ...baseValues };
    await sleep(1000);
    dispatch(setLoading());

    // Dispatch the update action with the updated values
    dispatch(updateVenue(updatedValues, values?.thumbnailHiResURL));
    setVenueDetailPage(true);
    setAddressDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <CardsList
            data={items}
            loading={itemsLoading}
            url={`/${user.role}/tickets`}
            cardTitle="Tickets"
          />
        );

      default:
        return null;
    }
  };

  const handlePrimaryDestinationChange = (value) => {
    if (!value || !value.label) {
      setSelectedPrimaryDestination({});
    } else {
      setSelectedPrimaryDestination(value);
    }
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      dispatch(getOneVenue(venue_id));
    }
    if (newValue === 1) {
      dispatch(setItemsLoading());
      dispatch(getVenueTicket(venue_id, venue?.primaryDestinationId?.id));
    }
  };

  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "address":
        detailPage = addressDetailPage;
        setDetailPage = setAddressDetailPage;
        break;
      default:
        detailPage = venueDetailPage;
        setDetailPage = setVenueDetailPage;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "information") {
        setVenueDetailPage(false);
        setAddressDetailPage(true);
      }
      if (type === "address") {
        setAddressDetailPage(false);
        setVenueDetailPage(true);
      }
      setImgDetailPage(true);
    };

    return (
      <VenueDetails
        type={type}
        formData={formData}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        handlePrimaryDestinationChange={handlePrimaryDestinationChange}
        selectedPrimaryDestination={selectedPrimaryDestination}
        setSelectedPrimaryDestination={setSelectedPrimaryDestination}
      />
    );
  }

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/venues?`;
      navigate(url);
    }
    //  else if (type === "partner") {
    //   url = `/partner/vernues?locationId=${recommendation?.location?._id}`;
    //   navigate(url);
    // }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                setFormRefVal(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      user={venue}
                      image={image}
                      imgDetailPage={imgDetailPage}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      imageType="thumbnailHiResURL"
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {" "}
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="venues"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setVenueDetailPage(true);
                            setAddressDetailPage(true);
                          }}
                          handleClose={() => {
                            _setImage(venue?.thumbnailHiResURL);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["information", "address"].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item size={{ xs: 12 }}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(item, {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      resetForm,
                                    })}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditVenue;
