/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Redux Actions
import { clearErrors, addAttraction } from "store/actions/attractionActions";
import setAlert from "store/actions/alertActions";

// Layouts
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";

// Schema
import { scrollToTop, useHandleError } from "components/helper";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import Review from "./components/Review";
import AttractionInfo from "./components/AttractionInfo";
import AdditionalInfo from "./components/AdditionalInfo";

const mainArray = ["Attraction Info", "Additional Info", "Review"];

function getSteps() {
  return mainArray;
}

function getStepContent(
  stepIndex,
  formData,
  selectedTravelDesk,
  handleTravelDeskChange,
  selectedDestination,
  handleDestinationChange,
  handleTagChange,
  selectedTag
) {
  if (formData) {
    switch (mainArray[stepIndex]) {
      case "Attraction Info":
        return (
          <AttractionInfo
            formData={formData}
            selectedTravelDesk={selectedTravelDesk}
            selectedDestination={selectedDestination}
            handleTravelDeskChange={handleTravelDeskChange}
            handleDestinationChange={handleDestinationChange}
          />
        );
      case "Additional Info":
        return (
          <AdditionalInfo
            formData={formData}
            selectedTag={selectedTag}
            handleTagChange={handleTagChange}
          />
        );
      case "Review":
        return <Review formData={formData} />;
      default:
        return null;
    }
  }
  return null;
}

const handleValidation = (activeStep) => {
  switch (mainArray[activeStep]) {
    case "Attraction Info":
      return validations[0];
    case "Additional Info":
      return validations[1];
    default:
      return null;
  }
};

const NewAttraction = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const error = useSelector((state) => state.users.error);

  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const { formId, formField } = form;
  const [formChange, setFormChange] = useState(null);
  const currentValidation = handleValidation(activeStep);
  const isLastStep = activeStep === steps.length - 1;
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [selectedTravelDesk, setSelectedTravelDesk] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  // useEffect

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (formChange) {
      setSteps(getSteps());
    }
  }, [formChange]);

  const handleDestinationChange = (value) => {
    if (!value || !value.label) {
      setSelectedDestination({});
    } else {
      setSelectedDestination(value);
    }
  };

  const handleTravelDeskChange = (e, value) => {
    if (value && value?._id) {
      setSelectedTravelDesk(value);
    } else {
      setSelectedTravelDesk({});
    }
  };

  const handleTagChange = (value) => {
    if (value && value?.value) {
      setSelectedTag(value);
    } else {
      setSelectedTag({});
    }
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTop(); // Scroll to the top
  };

  const submitForm = async (values, actions) => {
    const updatedValues = { ...values };
    if (updatedValues && updatedValues?.callToAction) {
      delete updatedValues?.callToAction?.urlType;
    }
    if (updatedValues && updatedValues?.destination) {
      updatedValues.destination = updatedValues?.destination?.value;
    }
    if (updatedValues && updatedValues?.tag) {
      updatedValues.tag = updatedValues?.tag?.value;
    }
    if (updatedValues && updatedValues?.retail_price && updatedValues.retail_price?.value) {
      delete updatedValues?.callToAction?.urlType;
      updatedValues.price = updatedValues.retail_price?.value;
    }
    if (updatedValues && updatedValues?.logo) {
      delete updatedValues?.logo;
    }
    await sleep(1000);
    // eslint-disable-next-line no-alert
    dispatch(addAttraction(updatedValues, values?.logo));
    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      navigate(`/admin/attractions`);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      scrollToTop(); // Scroll to the top
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={steps[activeStep] === "Review" ? undefined : currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          selectedTravelDesk,
                          handleTravelDeskChange,
                          selectedDestination,
                          handleDestinationChange,
                          handleTagChange,
                          selectedTag
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() => navigate("/admin/attractions")}
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "add" : "next"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewAttraction;
