/* eslint-disable no-underscore-dangle */

import PropTypes from "prop-types";

// @material-ui core components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ViewEditActions from "components/DetailList/ViewEditActions";
import TicketsPicker from "components/AsyncSelect/TicketsPicker";
import { saveExTicketFilter } from "store/actions/filterActions";

const ExcludeTicket = ({ locationId, filters, destination }) => {
  const dispatch = useDispatch();

  const [detail, setDetail] = useState(true);
  const [selectedTickets, setSelectedTickets] = useState([]);

  useEffect(() => {
    if (filters?.excludedTickets?.length >= 0) {
      setSelectedTickets(filters?.excludedTickets);
    }
  }, [filters]);

  const handleTicketChange = (value) => {
    selectedTickets?.map((val) => ({ value: val, label: val }));
    if (value) {
      setSelectedTickets(value);
    } else {
      setSelectedTickets([]);
    }
  };

  const handleSave = (event) => {
    event.preventDefault(); // Prevent form submission
    const newState = selectedTickets?.map((val) => val.value);
    dispatch(saveExTicketFilter(newState, locationId));
    setDetail(true);
  };

  return (
    <Grid item size={{ xs: 12 }}>
      <MDBox sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="body2">
          Choose the ticket you want to exclude from the above filter:
        </MDTypography>

        <ViewEditActions
          detailPage={detail}
          handleEdit={() => setDetail(false)}
          handleClose={() => {
            setDetail(true);
            setSelectedTickets(filters.excludedTickets);
          }}
          handleSave={handleSave}
        />
      </MDBox>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        sx={{
          width: "100%",
        }}
      >
        <Grid item size={{ xs: 12 }}>
          {detail ? (
            <MDBox style={{ display: "flex", flexDirection: "column" }}>
              {selectedTickets?.length > 0 &&
                selectedTickets?.map((item) => (
                  <MDTypography
                    key={item?._id || item?.value}
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    - {item?.title || item?.label}
                  </MDTypography>
                ))}
            </MDBox>
          ) : (
            <TicketsPicker
              destination={destination}
              tickets={{ name: "Ticket", Label: "Ticket" }}
              ticketsV={
                selectedTickets?.length > 0 && selectedTickets[0]?._id?.length > 0
                  ? selectedTickets?.map((item) => ({
                      label: item?.title,
                      value: item?._id,
                    }))
                  : selectedTickets
              }
              setFieldValue={() => {}}
              handleChange={handleTicketChange}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

// Setting default props
ExcludeTicket.defaultProps = {
  locationId: "",
  destination: "",
  filters: {},
};

// typechecking props for ExcludeTicket
ExcludeTicket.propTypes = {
  locationId: PropTypes.string,
  destination: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
};
export default ExcludeTicket;
