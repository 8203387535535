/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";

// Material UI components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

// Redux Actions
import {
  updateSpecificUser,
  updateUser,
  changePass,
  setUpdateLoadingUsers,
  clearErrors,
  checkUser,
} from "store/actions/userActions";
import setAlert from "store/actions/alertActions";

// Custom Components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";

// Schema components
import ViewEditActions from "components/DetailList/ViewEditActions";
import validations from "pages/users/edit-user/schema/validations";
import initialValues from "pages/users/edit-user/schema/initialValues";
import form from "pages/users/edit-user/schema/form";
import UserDetail from "pages/users/edit-user/components/UserDetail";
import ChangeMyPasswordDialog from "./components/ChangeMyPasswordDialog";

const Overview = () => {
  const { formId, formField } = form;

  const formRef = useRef();
  const dispatch = useDispatch();

  // Selector
  const user = useSelector((state) => state.users.user);
  const error = useSelector((state) => state.users.error);
  const linkSent = useSelector((state) => state.users.linkSent);
  // State

  // Check if a specific word is present in the URL
  const isWordInUrl = window.location.href.includes("profile");
  const [formChange, setFormChange] = useState(null);
  const [userValidation, setUserValidation] = useState(validations[0]);
  const [userDetailPage, setUserDetailPage] = useState(true);
  const [additionalDetailPage, setAdditionalDetailPage] = useState(true);
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [image, _setImage] = useState(user?.photo);
  const [initialState, setInitialState] = useState(initialValues);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [selectedPartner, setSelectedPartner] = useState(user?.partner);
  const [open, setOpen] = useState(false);

  //  UseEffect

  useEffect(() => {
    if (formChange) {
      if (formChange.role === "partner" && !additionalDetailPage) {
        setUserValidation(validations[1]);
      } else if (formChange.role === "curator" && !additionalDetailPage) {
        setUserValidation(validations[2]);
      } else if (!userDetailPage) {
        setUserValidation(validations[0]);
      } else {
        setUserValidation(undefined);
      }
    }
  }, [formChange, userDetailPage, additionalDetailPage]);

  useEffect(() => {
    if (linkSent) {
      dispatch(setAlert(error, "success"));
    } else if (error) {
      dispatch(setAlert(error, "error"));
    }
    dispatch(clearErrors());
  }, [linkSent, setAlert, clearErrors, error]);

  useEffect(() => {
    if (user?.photo) {
      _setImage(user?.photo);
    }
  }, [user?.photo]);

  useEffect(() => {
    dispatch(checkUser());
  }, []);

  // We cant use the location as is because of address picker and map picker
  useEffect(() => {
    if (user) {
      const manipulated = {};
      manipulated.name = user?.name || "";
      manipulated.email = user?.email || "";
      manipulated.phone = user?.phone || "";
      manipulated.dob = user?.dob || "";
      manipulated.photo = user?.photo || "";
      manipulated.role = user?.role || "";
      manipulated.partner = user?.partner ? user.partner?.name : "";
      manipulated.locations = user?.locations || [];
      manipulated.city = user?.city || "";
      manipulated.location = user?.location || "";
      manipulated.bio = user?.bio || "";
      manipulated.balance = user?.balance || 0;
      manipulated.commission = user?.commission || 0;
      manipulated.promoCodes = user?.promoCodes || [];

      manipulated.address = user?.address || "";
      manipulated.languages = user?.languages || [];
      manipulated.featured = user?.featured || false;
      manipulated._id = user?._id;
      setInitialState(manipulated);
    }
  }, [user]);
  // Functions

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    const {
      role,
      name = "",
      phone = "",
      email = "",
      dob = "",
      interests = [],
      bio = "",
      destinations = [],
      tickets = [],
      media = "",
      languages = [],
      balance = 0,
      commission = 0,
      promoCodes = [],
      city = "",
      location = "",
      partner = "",
      locations = [],
    } = values;

    const baseValues = { _id: values?._id, role, name, phone, email, dob };
    let additionalValues = {};
    if (!userDetailPage || (!additionalDetailPage && role === "admin")) {
      additionalValues = { name, phone, email, dob };
    } else if (!additionalDetailPage) {
      additionalValues =
        role === "curator"
          ? { interests, bio, destinations, languages, tickets, media }
          : role === "concierge"
          ? { location, city, balance, commission, promoCodes }
          : { partner, locations, name, phone, email, dob };
    }
    const updatedValues = { ...baseValues, ...additionalValues };
    if (updatedValues && updatedValues?.locations && user.role === "admin") {
      updatedValues.locations = updatedValues.locations.map((item) => item._id);
    } else if (updatedValues && updatedValues?.locations && user.role === "partner") {
      delete updatedValues?.locations;
    }
    await sleep(1000);
    if (isWordInUrl) {
      dispatch(
        updateUser(updatedValues, values?.photo !== user?.photo ? values?.photo : undefined)
      );
    } else dispatch(updateSpecificUser(updatedValues, values?.photo));

    setUserDetailPage(true);
    setAdditionalDetailPage(true);
  };
  const handleCityChange = (e, value) => {
    if (!value) {
      setSelectedCity({});
    } else {
      setSelectedCity(value);
    }
  };

  const handleLocationChange = (e, value) => {
    if (!value) {
      setSelectedLocation({});
    } else {
      setSelectedLocation(value);
    }
  };

  const handlePartnerChange = (e, value) => {
    if (!value || !value._id) {
      setSelectedPartner({});
    } else {
      setSelectedPartner(value);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={userValidation}
              onSubmit={submitForm}
              innerRef={(f) => {
                setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      isMyProfile
                      image={image}
                      imgDetailPage={imgDetailPage}
                      user={user}
                      imageType="photo"
                    >
                      <ViewEditActions
                        detailPage={imgDetailPage}
                        handleEdit={() => {
                          setUserDetailPage(true);
                          setAdditionalDetailPage(true);
                          setImgDetailPage(false);
                        }}
                        handleClose={() => {
                          _setImage(user?.photo);
                          setImgDetailPage(true);
                        }}
                        handleSave={() => {
                          submitForm(values);
                          setImgDetailPage(true);
                        }}
                      />
                    </DynamicHeader>

                    <UserDetail
                      user={user}
                      formField={formField}
                      selectedCity={selectedCity}
                      handleCityChange={handleCityChange}
                      selectedLocation={selectedLocation}
                      handleLocationChange={handleLocationChange}
                      selectedPartner={selectedPartner}
                      handlePartnerChange={handlePartnerChange}
                      userDetailPage={userDetailPage}
                      setUserDetailPage={setUserDetailPage}
                      additionalDetailPage={additionalDetailPage}
                      setAdditionalDetailPage={setAdditionalDetailPage}
                    />

                    <Card id="profile" sx={{ marginTop: "24px" }}>
                      <MDBox p={2}>
                        <Grid container spacing={3} justifyContent="space-between">
                          <Grid item size={{ xs: 12 }} textAlign="end">
                            <MDButton variant="gradient" color="dark" onClick={handleClickOpen}>
                              Change password
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <ChangeMyPasswordDialog
        user={user}
        changePass={changePass}
        openDialog={open}
        setOpen={setOpen}
        handleCloseDialog={handleCloseDialog}
        setUpdateLoadingUsers={setUpdateLoadingUsers}
      />
      <Footer />
    </DashboardLayout>
  );
};

export default Overview;
