/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DetailList from "components/DetailList";
import FeaturesPicker from "components/AutoComplete/FeaturesPicker";
import CuisinesPicker from "components/AutoComplete/CuisinePicker";
// import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "components/helper";
import TagsPicker from "components/AsyncSelect/TagsPicker";
import Danger from "components/Danger";
import removeHTMLTags from "utils/function/removeHTMLTags";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import { FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";

const Information = ({ formData, editPage, detailPage, selectedTags, handleTagsChange }) => {
  // const dispatch = useDispatch();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    tags,
    title,
    bookable,
    cuisines,
    features,
    subHeader,
    description,
    bookingEmail,
    minGuests,
    maxGuests,
    needsConfirmation,
  } = formField;
  const {
    title: titleV,
    // tags: tagsV,
    features: featuresV,
    cuisines: cuisinesV,
    bookable: bookableV,
    subHeader: subHeaderV,
    minGuests: minGuestsV,
    maxGuests: maxGuestsV,
    description: descriptionV,
    bookingEmail: bookingEmailV,
    needsConfirmation: needsConfirmationV,
  } = values;

  // console.log("values", values);
  const [bookableToggle, setBookableToggle] = useState(false);

  useEffect(() => {
    setBookableToggle(bookableV);
  }, [bookableV]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Information</MDTypography> : ""}
      </MDBox>

      {detailPage && editPage ? (
        <DetailList
          data={{
            tags: values?.tags,
            title: values?.title,
            cuisines: values?.cuisines,
            features: values?.features,
            subHeader: values?.subHeader,
            description: removeHTMLTags(values?.description),
            bookingEmail: values?.bookingEmail,
            minGuests: values?.minGuests,
            maxGuests: values?.maxGuests,
            bookable: values?.bookable,
            needsConfirmation: values?.needsConfirmation,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="logo" />
              </Grid>
            ) : (
              ""
            )}

            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
              />
            </Grid>

            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={subHeader.type}
                label={subHeader.label}
                name={subHeader.name}
                value={subHeaderV}
                placeholder={subHeader.placeholder}
                error={errors.subHeader && touched.subHeader}
                success={subHeaderV.length > 0 && !errors.subHeader}
              />
            </Grid>

            <Grid item size={{ xs: 12 }}>
              <FormField
                type={description.type}
                label={description.label}
                name={description.name}
                value={removeHTMLTags(descriptionV)}
                multiline
                outlined
                rows={5}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={descriptionV.length > 0 && !errors.description}
              />
            </Grid>

            <Grid item size={{ xs: 12 }}>
              <TagsPicker
                tags={tags}
                tagsV={
                  selectedTags?.length > 0 && selectedTags[0]?._id?.length > 0
                    ? selectedTags.map((item) => ({
                        label: item?.title,
                        value: item?._id,
                      }))
                    : selectedTags
                }
                setFieldValue={setFieldValue}
                handleChange={handleTagsChange}
                type="RECOMMENDATIONS"
              />

              <Danger validation={errors?.tags} />
            </Grid>

            <Grid item size={{ xs: 12, sm: 6 }}>
              <CuisinesPicker
                cuisines={cuisines}
                cuisinesV={cuisinesV}
                setFieldValue={setFieldValue}
              />
            </Grid>

            <Grid item size={{ xs: 12, sm: 6 }}>
              <FeaturesPicker
                features={features}
                featuresV={featuresV}
                setFieldValue={setFieldValue}
              />
            </Grid>

            <div style={{ marginLeft: "22px", width: "100%" }}>
              <FormControlLabel
                style={{ display: "flex", justifySelf: "flex-start" }}
                label={bookable.label}
                control={
                  <GreenSwitch
                    value={bookableV}
                    checked={bookableV}
                    name={bookable.name}
                    onChange={(event) => {
                      setBookableToggle(event.target.checked);
                      setFieldValue(bookable.name, event.target.checked);
                    }}
                  />
                }
              />

              {bookableToggle && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormField
                    value={bookingEmailV}
                    name={bookingEmail.name}
                    type={bookingEmail.type}
                    label={bookingEmail.label}
                    placeholder={bookingEmail.placeholder}
                    error={errors.bookable && touched.bookable}
                    success={bookableV?.length > 0 && !errors.bookable}
                  />

                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "40%" }}>
                      <FormField
                        value={minGuestsV}
                        type={minGuests.type}
                        name={minGuests.name}
                        label={minGuests.label}
                        placeholder={minGuests.placeholder}
                        error={errors.minGuests && touched.minGuests}
                        success={bookableV?.length > 0 && !errors.minGuests}
                      />
                    </div>

                    <div style={{ width: "40%" }}>
                      <FormField
                        value={maxGuestsV}
                        name={maxGuests.name}
                        type={maxGuests.type}
                        label={maxGuests.label}
                        placeholder={maxGuests.placeholder}
                        error={errors.maxGuests && touched.maxGuests}
                        success={bookableV?.length > 0 && !errors.maximumCover}
                      />
                    </div>
                  </div>

                  <FormControlLabel
                    style={{ display: "flex", justifySelf: "flex-start" }}
                    label={needsConfirmation.label}
                    control={
                      <GreenSwitch
                        value={needsConfirmationV}
                        checked={needsConfirmationV}
                        name={needsConfirmation.name}
                        onChange={(event) => {
                          setFieldValue(needsConfirmation.name, event.target.checked);
                        }}
                      />
                    }
                  />
                </div>
              )}
            </div>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};
Information.defaultProps = {
  editPage: false,
  detailPage: true,
  selectedTags: [],
  handleTagsChange: () => {},
};
// typechecking props for Information
Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  selectedTags: PropTypes.instanceOf(Object),
  handleTagsChange: PropTypes.func,
};

export default Information;
