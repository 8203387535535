/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import { Icon, IconButton, Tooltip } from "@mui/material";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";

// Material icon
import QrCodeIcon from "@mui/icons-material/QrCode";

const ConciergePromocodes = ({ color, title, icon, promoCodes, user, detailsPage }) => {
  const hasPercentageType = promoCodes?.some((item) => item.type === "percentage");
  const [qrData, setQRData] = useState(null);

  const downloadQRCode = (rowData) => {
    // Set the QR code data
    setQRData({
      value: `${
        user?.location
          ? `https://lokalee.app/locations/${user?.location?.slug}`
          : `https://lokalee.app/${user?.destinations[0]?.slug}`
      }?concierge=${
        user?._id
      }&utm_medium=QR_Code&utm_campaign=Concierge&utm_source=${encodeURIComponent(user?.name)}${
        rowData.code !== "No promo" ? `&promoCode=${rowData.code}` : ""
      }`,
      code: rowData.code,
    });

    // Wait for the QR code to render, then download
    setTimeout(() => {
      const canvas = document.querySelector("canvas");
      const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");

      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${rowData.code}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Reset QR data
      setQRData(null);
    }, 0);
  };

  const renderActionCell = (cell) => (
    <IconButton
      onClick={() => downloadQRCode(cell.row.original)}
      color="secondary"
      aria-label="download QR code"
      component="span"
      size="small"
    >
      <QrCodeIcon />
    </IconButton>
  );

  const renderActionCellUrl = (cell) => {
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const handleTooltipOpen = () => {
      setTooltipOpen(true);
    };

    const handleTooltipClose = () => {
      setTooltipOpen(false);
    };

    const urlValue = `${
      user?.location
        ? `https://lokalee.app/locations/${user?.location?.slug}`
        : user?.destinations
        ? `https://lokalee.app/${user?.destinations[0]?.slug}`
        : ""
    }?concierge=${
      user?._id
    }&utm_medium=QR_Code&utm_campaign=Concierge&utm_source=${encodeURIComponent(user?.name)}${
      cell?.row?.original?.code !== "No promo" ? `&promoCode=${cell?.row?.original?.code}` : ""
    }`;

    return (
      <div
        style={{ marginBottom: 10 }}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
      >
        <Tooltip title={urlValue} open={isTooltipOpen} arrow>
          <a href={urlValue} target="_blank" rel="noreferrer">
            https://lokalee.app..
          </a>
        </Tooltip>
      </div>
    );
  };

  const [state, setState] = useState({
    columns: [],
    rows: [],
  });

  const columnData = [
    { Header: "Code", accessor: "code" },
    { Header: `Value${hasPercentageType ? " (%)" : "($)"}`, accessor: "value" },
    {
      Header: "URL",
      accessor: "url",
      Cell: renderActionCellUrl,
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: renderActionCell, // Use the custom renderer
    },
  ];

  useEffect(() => {
    if (promoCodes?.length > 0) {
      const transformedData = promoCodes?.map((item, index) => ({
        id: `promo-${index}`, // Unique ID
        code: item.code || item?.label,
        value: item.amount,
      }));
      setState((prevState) => ({
        ...prevState,
        columns: columnData,
        rows: transformedData,
      }));
    } else {
      const transformedData = [
        {
          id: "no-promo", // Unique ID for no promo case
          code: "No promo",
          value: 0,
        },
      ];
      setState((prevState) => ({
        ...prevState,
        columns: columnData,
        rows: transformedData,
      }));
    }
  }, [promoCodes]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" pt={1} px={2}>
        {!detailsPage && (
          <>
            <MDBox
              variant="gradient"
              bgColor={color}
              color={color === "light" ? "dark" : "white"}
              coloredShadow={color}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              mt={-3}
            >
              <Icon fontSize="medium" color="inherit">
                {icon}
              </Icon>
            </MDBox>

            <MDBox textAlign="right" lineHeight={1.25} style={{ marginLeft: "12px" }}>
              <MDTypography variant="button" fontWeight="medium" color="text">
                {title}
              </MDTypography>
            </MDBox>
          </>
        )}
      </MDBox>
      <MDBox textAlign="center" lineHeight={1.25}>
        <Grid container justifyContent="start">
          <DataTable
            table={{
              columns: state.columns,
              rows: state.rows,
            }}
            entriesPerPage
            showTotalEntries
            isSorted
            noEndBorder
          />
        </Grid>
        {qrData && (
          <div style={{ display: "none" }}>
            <QRCode size={200} level="H" value={qrData.value} includeMargin />
          </div>
        )}
      </MDBox>
    </Card>
  );
};

// Setting default props
ConciergePromocodes.defaultProps = {
  color: "info",
  promoCodes: [],
  user: {},
  detailsPage: false,
  icon: null,
};

// typechecking props for ConciergePromocodes
ConciergePromocodes.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  promoCodes: PropTypes.instanceOf(Array),
  user: PropTypes.instanceOf(Object),
  detailsPage: PropTypes.bool,
};

export default ConciergePromocodes;
