/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
// import FormField from "components/FormField";
import DetailList from "components/DetailList";
import TypePicker from "components/AutoComplete/TypePicker";
import RecommendationPicker from "components/AsyncSelect/RecommendationPicker";
import ItemPicker from "components/AsyncSelect/ItemPicker";
import { useEffect, useState } from "react";
import MDDatePicker from "components/MDDatePicker";
import Danger from "components/Danger";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const Information = ({
  formData,
  editPage,
  detailPage,
  handleRecommendationChange,
  handleItemChange,
  selectedItem,
  selectedRecommendation,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { type, trendingStartD, trendingEndD, item, recommendation } = formField;
  const { trendingStart: trendingStartV, trendingEnd: trendingEndV } = values;

  const destinations = useSelector((state) => state.trends.destinationAndType);

  // State
  const [trendingStart, setTrendingStart] = useState("");
  const [trendingEnd, setTrendingEnd] = useState("");

  // UseEffect
  useEffect(() => {
    if (trendingStartV) {
      setTrendingStart(trendingStart);
    }
    if (trendingEndV) {
      setTrendingEnd(trendingEndV);
    }
  }, [trendingStartV, trendingEndV]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            item: values?.type === "item" && values?.item,
            recommendation: values?.type === "recommendation" && values?.recommendation,
            trendingStart: values?.trendingStart,
            trendingEnd: values?.trendingEnd,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12 }}>
              <TypePicker
                type={type}
                typeV={values?.type}
                setFieldValue={setFieldValue}
                error={errors.type && touched.type}
                success={values?.type?.name && !errors.type}
              />
            </Grid>
            {values?.type === "recommendation" && (
              <Grid item size={{ xs: 12 }}>
                <RecommendationPicker
                  destinations={destinations}
                  recommendation={recommendation}
                  setFieldValue={setFieldValue}
                  selectedRecommendation={selectedRecommendation || values?.recommendation}
                  handleChange={handleRecommendationChange}
                  error={errors?.recommendation && touched.recommendation}
                />
                {errors?.recommendation && touched?.recommendation && (
                  <Danger validation={errors?.recommendation} />
                )}
              </Grid>
            )}
            {values?.type === "item" && (
              <Grid item size={{ xs: 12 }}>
                <ItemPicker
                  destinations={destinations}
                  item={item}
                  itemObj={selectedItem || {}}
                  setFieldValue={setFieldValue}
                  handleChange={handleItemChange}
                />
                {errors?.item && touched?.item && <Danger validation={errors?.item} />}
              </Grid>
            )}
            <Grid item size={{ xs: 6 }}>
              <MDDatePicker
                input={{
                  type: trendingStartD.type,
                  label: trendingStartD.label,
                  name: trendingStartD.name,
                  variant: "standard",
                  width: "100%",
                  value: trendingStart || values?.trendingStart,
                }}
                onChange={(event, val) => {
                  if (val) {
                    const formattedDate = format(new Date(val), "yyyy-MM-dd");
                    setFieldValue("trendingStart", formattedDate);
                    setTrendingStart(formattedDate);
                  } else {
                    setFieldValue("trendingStart", ""); // Set to empty string to clear the field
                    setTrendingStart("");
                  }
                }}
                inputFormat="dd/MM/yyyy"
                error={errors.trendingStartD && touched.trendingStartD}
                success={values?.trendingStart?.length > 0 && !errors.trendingStartD}
                options={{
                  minDate: new Date(),
                  // dateFormat: "d/m/y",
                }}
              />
            </Grid>

            <Grid item size={{ xs: 6 }}>
              <MDDatePicker
                input={{
                  type: trendingEndD.type,
                  label: trendingEndD.label,
                  name: trendingEndD.name,
                  variant: "standard",
                  width: "100%",
                  value: trendingEnd || values?.trendingEnd,
                }}
                onChange={(event, val) => {
                  if (val) {
                    const formattedDate = format(new Date(val), "yyyy-MM-dd");
                    setFieldValue("trendingEnd", formattedDate);
                    setTrendingEnd(formattedDate);
                  } else {
                    setFieldValue("trendingEnd", ""); // Set to empty string to clear the field
                    setTrendingEnd("");
                  }
                }}
                success={values?.trendingEnd?.length > 0 && !errors.endDate}
                options={{
                  minDate: new Date(),
                  // dateFormat: "d-m-Y",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};
Information.defaultProps = {
  editPage: false,
  detailPage: true,
  selectedItem: {},
  selectedRecommendation: {},
  handleItemChange: () => {},
  handleRecommendationChange: () => {},
};
// typechecking props for Information
Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handleItemChange: PropTypes.func,
  handleRecommendationChange: PropTypes.func,
  selectedItem: PropTypes.instanceOf(Object),
  selectedRecommendation: PropTypes.instanceOf(Object),
};

export default Information;
