/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid2";
import FormField from "components/FormField";
import { useFormikContext } from "formik";
import Danger from "components/Danger";

const AgeAndTypePicker = ({ setFieldValue, ageBands, ageBandsV, minAge, maxAge, displayAge }) => {
  const typeOptions = ["TRAVELER", "INFANT", "CHILD", "YOUTH", "ADULT", "SENIOR"];
  const { errors, values, setValues, handleChange } = useFormikContext();

  const [ageBandAll, setAgeBandsAll] = useState([]);
  const [previousValue, setPreviousValue] = useState(ageBandsV || ageBandAll);

  useEffect(() => {
    // Function to extract ageBands from the nested structure
    const extractAgeBands = (availabilities) => {
      const ageBandsArr = [];
      availabilities?.bookableItems?.forEach((bookableItem) => {
        bookableItem?.seasons?.forEach((season) => {
          season?.pricingRecords?.forEach((pricingRecord) => {
            pricingRecord?.pricingDetails?.forEach((pricingDetail) => {
              if (pricingDetail?.ageBand) {
                ageBandsArr.push(pricingDetail?.ageBand);
              }
            });
          });
        });
      });
      return ageBandsArr;
    };

    const extractedAgeBands = extractAgeBands(values?.availabilities);
    setAgeBandsAll(extractedAgeBands);
  }, [values?.availabilities]);

  function deleteAndReindex(val, deleteIndex) {
    // Step 1: Delete the specified keys
    delete val[`startAge_${deleteIndex}`];
    delete val[`endAge_${deleteIndex}`];
    delete val[`minQty_${deleteIndex}`];
    delete val[`maxQty_${deleteIndex}`];
    delete val[`retailPrice_${deleteIndex}`];
    delete val[`netPrice_${deleteIndex}`];

    // Step 2: Remove the deleted index from ageBandsObject
    const updatedAgeBands = val.ageBandsObject.filter((_, index) => index !== deleteIndex);

    const updatedPricesObject = val.pricesObject.filter((_, index) => index !== deleteIndex);

    // Step 3: Re-index startAge and endAge pairs
    const newValues = {};
    let newIndex = 0;

    for (let i = 0; i < updatedAgeBands.length + 1; i++) {
      if (`startAge_${i}` in val && i !== deleteIndex) {
        newValues[`startAge_${newIndex}`] = val[`startAge_${i}`];
        newValues[`endAge_${newIndex}`] = val[`endAge_${i}`];
        newValues[`minQty_${newIndex}`] = val[`minQty_${i}`];
        newValues[`maxQty_${newIndex}`] = val[`maxQty_${i}`];
        newValues[`retailPrice_${newIndex}`] = val[`retailPrice_${i}`];
        newValues[`netPrice_${newIndex}`] = val[`netPrice_${i}`];
        newIndex++;
      }
    }

    // Step 4: Copy other keys that do not need re-indexing
    Object.keys(val).forEach((key) => {
      if (
        !key?.startsWith("startAge_") &&
        !key?.startsWith("endAge_") &&
        !key?.startsWith("minQty_") &&
        !key?.startsWith("maxQty_") &&
        !key?.startsWith("retailPrice_") &&
        !key?.startsWith("netPrice_")
      ) {
        newValues[key] = val[key];
      }
    });

    // Step 5: Set the updated ageBandsObject and new values
    newValues.ageBandsObject = updatedAgeBands;
    newValues.pricesObject = updatedPricesObject;

    // Update the form values
    setFieldValue("ageBandsObject", updatedAgeBands, false);
    setFieldValue("pricesObject", updatedPricesObject, false);
    setValues(newValues);

    return newValues;
  }

  const handleAgesChange = (val, type, index) => {
    const ageBandsArray = values.ageBands.map((ageBand, i) => {
      // Preserve the existing startAge and endAge unless they are being updated
      const updatedStartAge =
        i === index && type === "min"
          ? val !== ""
            ? parseInt(val, 10)
            : undefined
          : values[`startAge_${i}`];
      const updatedEndAge =
        i === index && type === "max"
          ? val !== ""
            ? parseInt(val, 10)
            : undefined
          : values[`endAge_${i}`];

      return {
        ageBand,
        startAge: updatedStartAge,
        endAge: updatedEndAge,
      };
    });

    setFieldValue("ageBandsObject", ageBandsArray);
  };

  return (
    <div>
      <Autocomplete
        id="ageBands"
        multiple
        filterSelectedOptions
        options={typeOptions}
        onChange={(event, selectedOptions) => {
          // Check if an option was removed
          if (selectedOptions?.length < previousValue?.length) {
            const deletedOption = previousValue.find((option) => !selectedOptions.includes(option));
            const deletedIndex = previousValue.findIndex((option) => option === deletedOption);

            const updatedValues = deleteAndReindex(values, deletedIndex);
            setValues(updatedValues);
          }
          setPreviousValue(selectedOptions);
          setFieldValue("ageBands", selectedOptions);
        }}
        value={ageBandsV}
        getOptionLabel={(option) => option || ""}
        renderInput={(params) => (
          <FormField
            {...params}
            label={ageBands.label}
            name={ageBands?.name}
            onChange={() => null}
          />
        )}
      />
      {displayAge &&
        ageBandsV?.map((selectedOption, index) => (
          <Grid container spacing={2} key={`age-picker-${index}`}>
            <Grid item size={{ xs: 3 }}>
              <h5>{selectedOption}</h5>
            </Grid>
            <Grid item size={{ xs: 4.5 }}>
              <FormField
                type={minAge.type}
                label={minAge.label}
                name={`${minAge.name}_${index}`}
                // defaultValue={minAgeV[index]}
                value={values[`startAge_${index}`] || ""}
                placeholder={minAge.placeholder}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const value = parseFloat(inputValue);

                  // Allow empty string or non-negative numbers
                  if (inputValue === "" || (!Number.isNaN(value) && value >= 0)) {
                    handleChange(e);
                    handleAgesChange(inputValue, "min", index);
                  } else {
                    // Optionally reset the input to 0 if a negative value is entered
                    e.target.value = 0;
                  }
                }}
              />
            </Grid>
            <Grid item size={{ xs: 4.5 }}>
              <FormField
                type={maxAge.type}
                label={maxAge.label}
                name={`${maxAge.name}_${index}`}
                // defaultValue={maxAgeV[index]}
                value={values[`endAge_${index}`] || ""}
                placeholder={maxAge.placeholder}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const value = parseFloat(inputValue);

                  // Allow empty string or non-negative numbers
                  if (inputValue === "" || (!Number.isNaN(value) && value >= 0)) {
                    handleChange(e);
                    handleAgesChange(inputValue, "max", index);
                  } else {
                    // Optionally reset the input to 0 if a negative value is entered
                    e.target.value = 0;
                  }
                }}
              />
            </Grid>
          </Grid>
        ))}{" "}
      {errors?.ageBandsObject?.length > 0 && <Danger validation={errors?.ageBandsObject} />}
    </div>
  );
};

AgeAndTypePicker.defaultProps = {
  minAge: {},
  minAgeV: [],
  maxAge: {},
  maxAgeV: [],
  displayAge: false, // Default to not displaying age
};

AgeAndTypePicker.propTypes = {
  ageBands: PropTypes.instanceOf(Object).isRequired,
  ageBandsV: PropTypes.instanceOf(Array).isRequired,
  minAge: PropTypes.instanceOf(Object),
  maxAge: PropTypes.instanceOf(Object),
  minAgeV: PropTypes.instanceOf(Array),
  maxAgeV: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func.isRequired,
  displayAge: PropTypes.bool, // Prop to control whether to display age or not
};

export default AgeAndTypePicker;
