import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import React from "react";
import PropTypes from "prop-types";

const Loader = ({ size }) => (
  <Stack direction="column">
    <Grid container justifyContent="center">
      <CircularProgress size={size || 24} color="primaryC" />
    </Grid>
  </Stack>
);

// Setting default props
Loader.defaultProps = {
  size: 24,
};

// typechecking props for Loader
Loader.propTypes = {
  size: PropTypes.number,
};

export default Loader;
