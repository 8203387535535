/* eslint-disable no-underscore-dangle */
import {
  SET_ITEM_LOADING,
  ITEM_ADD_SUCCESS_STEP0,
  ITEM_ADD_SUCCESS_STEP1,
  ITEM_ADD_SUCCESS_STEP2,
  ITEM_ADD_SUCCESS_STEP3,
  ITEM_ADD_SUCCESS_STEP4,
  ITEM_FAIL,
  GET_ITEMS,
  CLEAR_ERRORS,
  DELETE_ITEM,
  GET_ONE_ITEM,
  UPDATE_ITEM,
  LOGOUT,
  DELETE_SINGLE_ITEM,
  UPDATE_ITEM_TRANSLATION,
  GENERATE_ITEMS_LIST,
  GENERATE_ITEMS_LIST_FAIL,
  SET_ITEM_WIDGET_LOADING,
  GET_GENERATED_ITEMS,
  CLEAR_GENERATED_ITEMS,
  CHECK_ITEMS,
  SET_ITEM_EMPTY,
  GET_UPDATED_TICKET_TYPE,
  GET_TICKET_TRANSLATION,
} from "../actions/Types";

const initialState = {
  loading: false,
  items: [],
  error: null,
  added: false,
  itemStep0: null,
  itemStep1: null,
  itemStep2: null,
  itemStep3: null,
  itemStep4: null,
  tag: null,
  widget: "",
  widgetLoading: false,
  ticketType: { type: "lokalee" },
  translationTicket: {},
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case ITEM_ADD_SUCCESS_STEP0:
      return {
        ...state,
        loading: false,
        error: null,
        items: [...state.items, action.payload.data],
        added: true,
        itemStep0: action.payload.data,
      };
    case ITEM_ADD_SUCCESS_STEP1:
      return {
        ...state,
        loading: false,
        error: null,
        items: [...state.items, action.payload.data],
        added: true,
        itemStep1: action.payload.data.data,
      };
    case ITEM_ADD_SUCCESS_STEP2:
      return {
        ...state,
        loading: false,
        error: null,
        items: [...state.items, action.payload.data],
        added: true,
        itemStep2: action.payload.data,
      };
    case ITEM_ADD_SUCCESS_STEP3:
      return {
        ...state,
        loading: false,
        error: null,
        items: [...state.items, action.payload.data],
        added: true,
        itemStep3: action.payload.data,
      };
    case ITEM_ADD_SUCCESS_STEP4:
      return {
        ...state,
        loading: false,
        error: null,
        items: [...state.items, action.payload.data],
        added: true,
        itemStep0: {},
        itemStep4: action.payload.data,
      };
    case GET_ONE_ITEM:
      return {
        ...state,
        loading: false,
        error: null,
        item: action.payload.data,
        added: false,
      };
    case SET_ITEM_EMPTY:
      return {
        ...state,
        loading: false,
        error: null,
        item: {},
        itemStep0: null,
        itemStep1: null,
        itemStep2: null,
        itemStep3: null,
        itemStep4: null,
      };
    case GET_TICKET_TRANSLATION:
      return {
        ...state,
        translationTicket: action.payload.data,
      };

    case GET_ITEMS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.data,
        added: false,
        item: null,
      };
    case CHECK_ITEMS:
      return {
        ...state,
        loading: false,
        error: null,
        itemsExp: action.payload.data,
        added: false,
        item: null,
      };
    case DELETE_ITEM:
      return {
        ...state,
        loading: false,
        items: state.itemsExp.filter((item) => item._id !== action.payload.data.item._id),
      };
    // case DELETE_ITEM:
    //   return {
    //     ...state,
    //     loading: false,
    //     items: state.items.filter((item) => item._id !== action.payload.data.item._id),
    //   };
    case DELETE_SINGLE_ITEM:
      return {
        ...state,
        loading: false,
        item: null,
      };
    case UPDATE_ITEM:
      return {
        ...state,
        added: true,
        items: state.items.map((item) =>
          item._id === action.payload.data.item._id ? action.payload.data.item : item
        ),
      };
    case UPDATE_ITEM_TRANSLATION:
      return {
        ...state,
        translationTicket: action.payload.data,
      };
    case ITEM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_UPDATED_TICKET_TYPE:
      return {
        ...state,
        ticketType: action.payload,
      };
    case GENERATE_ITEMS_LIST:
      return {
        ...state,
        added: true,
        widget: action.payload,
        widgetLoading: false,
      };
    case GENERATE_ITEMS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        widgetLoading: false,
      };
    case SET_ITEM_WIDGET_LOADING:
      return {
        ...state,
        widgetLoading: true,
      };
    case GET_GENERATED_ITEMS:
      return {
        ...state,
        added: false,
        widget: action.payload,
        widgetLoading: false,
      };
    case CLEAR_GENERATED_ITEMS:
      return {
        ...state,
        loading: false,
        error: null,
        widget: "",
        widgetLoading: false,
      };
    case SET_ITEM_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        items: [],
        error: null,
        added: false,
        item: null,
      };

    default:
      return state;
  }
};
export default variable;
