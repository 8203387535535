import React from "react";
import PropTypes from "prop-types";

import FooterAddress from "./Address/index";

const socialLinks = [
  {
    alt: "Instagram",
    href: "https://www.instagram.com/lokalee.app/",
    src: "https://email-assets.lokalee.app/email/emailImages/instagram.png",
  },
  {
    alt: "LinkedIn",
    href: "https://www.linkedin.com/company/linkedin.com-lokalee/mycompany/",
    src: "https://email-assets.lokalee.app/email/emailImages/linkedin.png",
  },
  {
    alt: "Facebook",
    href: "https://www.facebook.com/lokaleeapp",
    src: "https://email-assets.lokalee.app/email/emailImages/facebook.png",
  },
];
const appLinks = [
  {
    href: "https://apps.apple.com/lb/app/lokalee/id1612033165",
    src: "https://email-assets.lokalee.app/email/emailImages/AppStore.png",
  },
  {
    href: "https://play.google.com/store/apps/details?id=app.lokalee&pcampaignid=web_share",
    src: "https://email-assets.lokalee.app/email/emailImages/googlePlay.png",
  },
];

const FooterSection = ({ hasSocialLinks }) => {
  const handleSocialLinks = (link) => {
    const { href, src, alt } = link;

    return (
      <td style={{ paddingRight: "14.5px" }}>
        <a href={href}>
          <img src={src} alt={alt} style={{ width: "34px", height: "34px", display: "block" }} />
        </a>
      </td>
    );
  };
  const handleAppLinks = (link) => {
    const { href, src, alt } = link;

    return (
      <td style={{ padding: "0 5px" }}>
        <a href={href}>
          <img alt={alt} style={{ width: "95.73px", height: "34px", display: "block" }} src={src} />
        </a>
      </td>
    );
  };

  return (
    <table
      width="100%"
      cellPadding="0"
      cellSpacing="0"
      role="presentation"
      style={{
        fontSize: "12px",
        color: "#888888",
      }}
    >
      <tbody>
        {hasSocialLinks && (
          <tr>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "16px 0px 10px 0px",
              }}
            >
              <table
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
                style={{ width: "50%" }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        margin: 0,
                        height: "32px",
                        fontSize: "17px",
                        color: "#000000",
                        fontWeight: "bold",
                        textAlign: "center",
                        lineHeight: "19.55px",
                      }}
                    >
                      Download the app
                    </td>
                  </tr>

                  <tr>
                    <td aria-label="App download links">
                      <table
                        width="100%"
                        style={{
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <tbody>
                          <tr>{appLinks.map((link) => handleAppLinks(link))}</tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
                style={{ width: "50%" }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        height: "32px",
                        fontSize: "17px",
                        color: "#000000",
                        fontWeight: "700",
                        textAlign: "center",
                        lineHeight: "19.55px",
                      }}
                    >
                      Follow Us
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <table cellPadding="0" cellSpacing="0" role="presentation">
                        <tbody>
                          <tr>
                            {socialLinks.map((link) => handleSocialLinks(link))}
                            {/* <td style={{ paddingRight: "14.5px" }}>
                              <a
                                href="https://www.instagram.com/lokalee.app/"
                                aria-label="Instagram"
                              >
                                <img
                                  alt="Instagram"
                                  style={{ width: "34px", height: "34px", display: "block" }}
                                  src="https://email-assets.lokalee.app/email/emailImages/instagram.png"
                                />
                              </a>
                            </td>

                            <td style={{ paddingRight: "14.5px" }}>
                              <a
                                aria-label="LinkedIn"
                                href="https://www.linkedin.com/company/linkedin.com-lokalee/mycompany/"
                              >
                                <img
                                  alt="LinkedIn"
                                  style={{ width: "34px", height: "34px", display: "block" }}
                                  src="https://email-assets.lokalee.app/email/emailImages/linkedin.png"
                                />
                              </a>
                            </td>

                            <td>
                              <a href="https://www.facebook.com/lokaleeapp" aria-label="Facebook">
                                <img
                                  alt="Facebook"
                                  style={{ width: "34px", height: "32px", display: "block" }}
                                  src="https://email-assets.lokalee.app/email/emailImages/facebook.png"
                                />
                              </a>
                            </td> */}
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        )}

        <tr>
          <td style={{ textAlign: "center" }}>
            <FooterAddress />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default FooterSection;

FooterSection.defaultProps = {
  hasSocialLinks: true,
};

FooterSection.propTypes = {
  hasSocialLinks: PropTypes.bool,
};
