/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import RolePicker from "components/AutoComplete/RolePicker";
import { capitalizeFirstLetter } from "components/helper";
import { useSelector } from "react-redux";

const UserInfo = ({ formData }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { name, email, role } = formField;
  const { name: nameV, email: emailV, role: roleV } = values;
  const user = useSelector((state) => state.users.user);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">User info</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item size={{ xs: 12, sm: 6 }}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={capitalizeFirstLetter(nameV)}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV.length > 0 && !errors.name}
            />
          </Grid>
          <Grid item size={{ xs: 12, sm: 6 }}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              // error={errors.email && touched.email}
              // success={emailV.length > 0 && !errors.email}
              disabled={user.role === "partner"}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item size={{ xs: 12 }}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <RolePicker
                role={role}
                roleV={roleV}
                setFieldValue={setFieldValue}
                error={errors.role && touched.role}
                success={roleV?.name && !errors.role}
                disabled={user.role === "partner"}
              />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
};

export default UserInfo;
