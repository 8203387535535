import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import Checkbox from "@mui/material/Checkbox";
import styled from "@emotion/styled";

import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import MDTimePicker from "components/MDTimePicker";

const FabContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const FabButton = styled(Fab)`
  background-color: ${(props) => (props.active ? "#00acc1" : "white")};
  color: ${(props) => (props.active ? "white" : "black")};
  border: 1px solid ${(props) => (props.active ? "#00acc1" : "black")};
  &:hover {
    background-color: ${(props) => (props.active ? "#00acc1" : "white")};
  }
`;

const OpeningHoursDialog = ({
  title,
  onOpen,
  onClose,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  dayClicked,
  handleAdd,
  openAllDayCheckbox,
  closedCheckbox,
  openAllDay,
  closed,
  start,
  end,
  handleChange,
  edit,
  selectAllDays,
  setSelectAllDays,
  handleSelectAllDays,
}) => {
  useEffect(() => {
    if (onOpen) {
      setSelectAllDays(monday && tuesday && wednesday && thursday && friday && saturday && sunday);
    }
  }, [onOpen, monday, tuesday, wednesday, thursday, friday, saturday, sunday]);

  return (
    <Dialog
      fullWidth
      open={onOpen}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-paper": {
          height: "auto",
          width: { sm: "100%", md: "680px" },
          minWidth: { md: "680px" },
          padding: "24px",
        },
      }}
    >
      <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>
      <DialogContent>
        <FabContainer>
          <FabButton
            active={monday || undefined}
            aria-label="add"
            onClick={() => dayClicked("monday")}
            disabled={edit && !monday}
          >
            <span>M</span>
          </FabButton>
          <FabButton
            active={tuesday || undefined}
            aria-label="add"
            onClick={() => dayClicked("tuesday")}
            disabled={edit && !tuesday}
          >
            <span>T</span>
          </FabButton>
          <FabButton
            active={wednesday || undefined}
            aria-label="add"
            onClick={() => dayClicked("wednesday")}
            disabled={edit && !wednesday}
          >
            <span>W</span>
          </FabButton>
          <FabButton
            active={thursday || undefined}
            aria-label="add"
            onClick={() => dayClicked("thursday")}
            disabled={edit && !thursday}
          >
            <span>T</span>
          </FabButton>
          <FabButton
            active={friday || undefined}
            aria-label="add"
            onClick={() => dayClicked("friday")}
            disabled={edit && !friday}
          >
            <span>F</span>
          </FabButton>
          <FabButton
            active={saturday || undefined}
            aria-label="add"
            onClick={() => dayClicked("saturday")}
            disabled={edit && !saturday}
          >
            <span>S</span>
          </FabButton>
          <FabButton
            active={sunday || undefined}
            aria-label="add"
            onClick={() => dayClicked("sunday")}
            disabled={edit && !sunday}
          >
            <span>S</span>
          </FabButton>
        </FabContainer>

        {!(monday || tuesday || wednesday || thursday || friday || saturday || sunday) && (
          <div
            style={{
              fontSize: "12px",
              marginTop: "12px",
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            You should choose at least one day
          </div>
        )}
        <div
          style={{
            marginTop: "2em",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={openAllDayCheckbox}
                  onChange={(val) => openAllDay(val.target.checked)}
                />
              }
              label="Open 24 hours"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox checked={closedCheckbox} onChange={(val) => closed(val.target.checked)} />
              }
              label="Closed"
            />
          </div>
          {!edit && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAllDays}
                    onChange={(event) => handleSelectAllDays(event.target.checked)}
                  />
                }
                label="All Week"
              />
            </div>
          )}
        </div>

        {!openAllDayCheckbox && !closedCheckbox ? (
          <Grid
            container
            spacing={3}
            style={{
              marginTop: "50px",
              marginBottom: "30px",
            }}
          >
            <Grid item size={{ xs: 5 }}>
              <Typography
                style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}
              >
                Open time:
              </Typography>{" "}
              <MDTimePicker
                key={start}
                input={{
                  type: "text",
                  name: "start",
                  variant: "standard",
                  width: "100%",
                  value: start || undefined,
                }}
                onClose={(e, time) => {
                  handleChange(e, time, "start");
                }}
              />
            </Grid>
            <Grid item size={{ xs: 5 }}>
              <Typography
                style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}
              >
                Close time:
              </Typography>{" "}
              <MDTimePicker
                key={end}
                input={{
                  type: "text",
                  name: "end",
                  variant: "standard",
                  width: "100%",
                  value: end || undefined,
                }}
                onClose={(e, time) => {
                  handleChange(e, time, "end");
                }}
                error={end > start}
              />
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAdd}>{edit ? "Edit" : "Add"}</Button>
      </DialogActions>
    </Dialog>
  );
};

// Setting default props
OpeningHoursDialog.defaultProps = {
  title: "",
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
  openAllDayCheckbox: false,
  closedCheckbox: false,
  edit: false,
  start: "",
  end: "",
  selectAllDays: false,
  handleSelectAllDays: () => {},
  setSelectAllDays: () => {},
};
OpeningHoursDialog.propTypes = {
  title: PropTypes.string,
  onOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  monday: PropTypes.bool,
  tuesday: PropTypes.bool,
  wednesday: PropTypes.bool,
  thursday: PropTypes.bool,
  friday: PropTypes.bool,
  saturday: PropTypes.bool,
  sunday: PropTypes.bool,
  dayClicked: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  openAllDayCheckbox: PropTypes.bool,
  closedCheckbox: PropTypes.bool,
  openAllDay: PropTypes.func.isRequired,
  closed: PropTypes.func.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  selectAllDays: PropTypes.bool,
  handleSelectAllDays: PropTypes.func,
  setSelectAllDays: PropTypes.func,
};

export default OpeningHoursDialog;
