/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
/**
=========================================================
* LCM Dashboard V2
=========================================================

* Product Page: https://www.lcm.lokalee.app
* Copyright 2023 Lokalee (https://www.lokalee.app)

 =========================================================
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// actions

// Newticketinfo page components
import FormField from "components/FormField";
import DetailList from "components/DetailList";
// import GreenSwitch from "components/AutoComplete/GreenSwitch";
import { capitalizeFirstLetter } from "components/helper";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import TagPicker from "components/AsyncSelect/TagPicker";
import Danger from "components/Danger";
import LocationPicker from "components/AutoComplete/LocationPicker";
import ThemesPicker from "components/AutoComplete/ThemesPicker";
import CuratorPicker from "components/AsyncSelect/CuratorPicker";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
import PrimaryDestinationPicker from "components/AutoComplete/PrimaryDestinationPicker";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LocationUserPartner from "components/LocationUserPartner";

const TicketInfo = ({
  formData,
  editPage,
  detailPage,
  handleTagChange,
  selectedTag,
  selectedLocation,
  handleLocationChange,
  selectedCurator,
  setSelectedCurator,
  handleCuratorChange,
  handlePrimaryDestinationChange,
  selectedPrimaryDestination,
  handleSecondaryDestinationChange,
  selectedSecondaryDestination,
  setSelectedPrimaryDestination,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    title,
    type,
    tag,
    themes,
    overview,
    location,
    curator,
    curatorName,
    curatorContact,
    curatorEmail,
    providerName,
    providerEmail,
    providerContact,
    curatorOrProv,
    destinations,
    primaryDestination,
  } = formField;
  const {
    title: titleV,
    type: typeV,
    curatorOrProv: curatorOrProvV,
    // tag: tagV,
    themes: themesV,
    overview: overviewV,
    // skipTheLine: skipTheLineV,
    location: locationV,
    curatorName: curatorNameV,
    curatorContact: curatorContactV,
    curatorEmail: curatorEmailV,
    destinations: destinationsV,
    primaryDestination: primaryDestinationV,
  } = values;

  const [curOrProvVal, setCurOrProvVal] = useState(values?.CuratorPicker || "curator");
  const [typeVal, setTypeVal] = useState("ticket");

  const locations = useSelector((state) => state.locations.locations);
  const user = useSelector((state) => state.users.user);
  const locationPartnerId = useSelector((state) => state.locations.location);

  const locationPartner = locations
    .map((loc) => (loc._id === locationPartnerId ? loc : null))
    .find((loc) => loc !== null);

  const typeOptions = [
    { value: "tour", label: "Tour" },
    { value: "activity", label: "Activity" },
    { value: "ticket" || "STANDARD", label: "Ticket" },
    { value: "rental", label: "Rental" },
    { value: "transport", label: "Transport" },
  ];

  const curatorOrProvOptions = [
    { value: "curator", label: "Curator" },
    { value: "provider", label: "Provider" },
  ];

  useEffect(() => {
    if (values?.curator?.name) {
      setCurOrProvVal("curator");
      setFieldValue("curatorOrProv", "curator");
    } else if (values?.providerName) {
      setCurOrProvVal("provider");
      setFieldValue("curatorOrProv", "provider");
      setFieldValue("curator", null);
    }
  }, [values?.curator?.name, values?.providerName]);

  useEffect(() => {
    if (user?.role === "partner") {
      setFieldValue("location", locationPartner?._id || locations[0]?._id);
    }
  }, [locationPartner, locations[0]?._id]);

  useEffect(() => {
    if (values?.curatorOrProv) {
      setCurOrProvVal(values?.curatorOrProv);
    }
  }, [values?.curatorOrProv]);

  useEffect(() => {
    if (values?.itinerary?.itineraryType === "STANDARD") {
      setTypeVal("ticket");
      setFieldValue("type", "ticket");
    }
  }, [values?.itinerary?.itineraryType]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Ticket Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            type: values?.itinerary?.itineraryType === "STANDARD" && "ticket",
            tag: values?.tags[0]?.label,
            themes: values?.tags.slice(1).map((t) => t.label),
            overview: values?.overview,
            destinations: values?.destinations,
            primaryDestination: values?.primaryDestination?.destinationName,
            location: values?.location,
            providerName: values?.provider?.name,
            providerContact: values?.provider?.contact,
            providerEmail: values?.provider?.email,
            curatorName: values?.curator?.name,
            curatorContact: values?.curator?.phone,
            curatorEmail: values?.curator?.id?.email || values?.curator?.email,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <DynamicRadioGroup
                groupLabel={type.label}
                name={type.name}
                value={typeV || typeVal}
                options={typeOptions}
                setFieldValue={setFieldValue}
                disabled
              />
            </Grid>{" "}
            <Grid container spacing={2} item size={{ xs: 12 }}>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <TagPicker
                  ticketPage
                  tag={tag}
                  handleChange={handleTagChange}
                  setFieldValue={setFieldValue}
                  selectedTag={
                    selectedTag?.length > 0
                      ? selectedTag[0]
                      : values?.tag?.value
                      ? values?.tag
                      : values?.tags[0] || selectedTag
                  }
                />
                {errors?.tag && !values?.tag?.label && touched?.tag && (
                  <Danger validation={errors?.tag} />
                )}
              </Grid>

              <Grid item size={{ xs: 12, sm: 6 }}>
                <ThemesPicker
                  themes={themes}
                  themesV={themesV?.length > 0 ? themesV : values?.tags?.slice(1)}
                  setFieldValue={setFieldValue}
                  error={errors.themesV && touched.themesV}
                  success={values?.themes?.length > 0 && !errors.themes}
                  selectedTag={
                    selectedTag?.length > 0
                      ? selectedTag[0]
                      : values?.tag?.value
                      ? values?.tag
                      : values?.tags[0] || selectedTag
                  }
                />
              </Grid>
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={overview.type}
                label={overview.label}
                name={overview.name}
                value={overviewV}
                multiline
                outlined
                rows={5}
                placeholder={overview.placeholder}
                error={errors.overview && touched.overview}
                success={overviewV?.length > 0 && !errors.overview}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <DestinationsPicker
                setFieldValue={setFieldValue}
                handleChange={handleSecondaryDestinationChange}
                destinations={destinations}
                destinationsV={destinationsV || selectedSecondaryDestination}
                setSelectedPrimaryDestination={setSelectedPrimaryDestination}
              />
              <Danger validation={errors?.destinations} />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <PrimaryDestinationPicker
                setFieldValue={setFieldValue}
                handleChange={handlePrimaryDestinationChange}
                selectedDestination={
                  destinationsV.length === 0
                    ? null // If no destinations are selected, clear selectedPrimaryDestination
                    : selectedPrimaryDestination &&
                      Object.keys(selectedPrimaryDestination).length > 0 &&
                      destinationsV.some(
                        (destination) => destination.label === selectedPrimaryDestination.label
                      )
                    ? selectedPrimaryDestination // Display selectedPrimaryDestination if it's not empty and it's included in destinationsV
                    : destinationsV.some(
                        (destination) => destination.label === primaryDestinationV?.destinationName
                      )
                    ? primaryDestinationV // Display primaryDestinationV if selectedPrimaryDestination is empty or not included in destinationsV
                    : null // Clear selectedPrimaryDestination if it's not in destinationsV but primaryDestinationV is also not in destinationsV
                }
                destination={primaryDestination}
                destinationsObj={destinationsV || selectedSecondaryDestination}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              {user.role === "admin" && location ? (
                <Grid item size={{ xs: 12 }}>
                  <LocationPicker
                    setFieldValue={setFieldValue}
                    location={{ label: location.label, name: "locCity.location" }}
                    locationOnly
                    selectedLocation={
                      selectedLocation?.name ? selectedLocation : values?.location || {}
                    }
                    handleLocationChange={handleLocationChange}
                    error={errors.locCity?.location && touched.location}
                    success={locationV?.name && !errors?.locCity?.location}
                  />
                </Grid>
              ) : (
                <Grid item size={{ xs: 12 }} sx={{ mb: 3 }}>
                  <LocationUserPartner
                    defaultLocationId={locationPartner?._id || locations[0]?._id}
                    disabled
                    handleChange={handleLocationChange}
                    locations={locations || {}}
                    location={location}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <Grid item size={{ xs: 12 }}>
                <DynamicRadioGroup
                  groupLabel={curatorOrProv.label}
                  name={curatorOrProv.name}
                  value={curatorOrProvV || curOrProvVal}
                  options={curatorOrProvOptions}
                  setFieldValue={setFieldValue}
                  handleChange={() => {
                    setCurOrProvVal(values?.curatorOrProvider);
                    setSelectedCurator({});
                    setFieldValue("curator", null);
                    setFieldValue("providerName", "");
                    setFieldValue("providerEmail", "");
                    setFieldValue("provider", {});
                  }}
                />
              </Grid>
            </Grid>
            {errors.curator && <div className="danger">{errors.curator.name}</div>}{" "}
            {curatorOrProvV === "curator" ||
            (curOrProvVal === "curator" && Object.keys(values.provider).length === 0) ? (
              <>
                <Grid item size={{ xs: 12 }}>
                  <CuratorPicker
                    curator={curator}
                    handleChange={handleCuratorChange}
                    setFieldValue={setFieldValue}
                    selectedCurator={values?.curator}
                  />

                  {errors?.curator && values?.curatorOrProv === "curator" && (
                    <Danger validation={errors?.curator} />
                  )}
                </Grid>
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    key={selectedCurator?.label || Object.keys(selectedCurator).length}
                    type={curatorName.type}
                    label={curatorName.label}
                    name={curatorName.name}
                    value={values?.curator?.label || values?.curator?.name}
                    placeholder={curatorName.placeholder}
                    error={errors.curatorName && touched.curatorName}
                    success={curatorNameV?.length > 0 && !errors.curatorName}
                    disabled
                  />
                </Grid>
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    key={selectedCurator?.label || Object.keys(selectedCurator).length}
                    type={curatorContact.type}
                    label={curatorContact.label}
                    name={curatorContact.name}
                    value={values?.curator?.id?.phone || values?.curator?.phone}
                    placeholder={curatorContact.placeholder}
                    error={errors.curatorContact && touched.curatorContact}
                    success={curatorContactV?.length > 0 && !errors.curatorContact}
                    disabled
                  />
                </Grid>
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    key={selectedCurator?.label || Object.keys(selectedCurator).length}
                    type={curatorEmail.type}
                    label={curatorEmail.label}
                    name={curatorEmail.name}
                    value={values?.curator?.id?.email || values?.curator?.email}
                    placeholder={curatorEmail.placeholder}
                    error={errors.curatorEmail && touched.curatorEmail}
                    success={curatorEmailV?.length > 0 && !errors.curatorEmail}
                    disabled
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    type={providerName.type}
                    label={providerName.label}
                    name="provider.name"
                    value={values.provider?.name || ""}
                    placeholder={providerName.placeholder}
                    error={errors.provider?.name && touched.provider?.name}
                    success={values.provider?.name?.length > 0 && !errors.provider?.name}
                  />
                </Grid>
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    type={providerContact.type}
                    label={providerContact.label}
                    name="provider.contact"
                    value={values.provider?.contact || ""}
                    placeholder={providerContact.placeholder}
                    error={errors.provider?.contact && touched.provider?.contact}
                    success={values.provider?.contact?.length > 0 && !errors.provider?.contact}
                  />
                </Grid>
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    type={providerEmail.type}
                    label={providerEmail.label}
                    name="provider.email"
                    value={values.provider?.email || ""}
                    inputProps={{ type: "email" }}
                    placeholder={providerEmail.placeholder}
                    error={errors.provider?.email && touched.provider?.email}
                    success={values.provider?.email?.length > 0 && !errors.provider?.email}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};
TicketInfo.defaultProps = {
  handleTagChange: () => {},
  selectedTag: {},
  selectedLocation: {},
  handleLocationChange: () => {},
  selectedCurator: {},
  setSelectedCurator: () => {},
  handleCuratorChange: () => {},
  handlePrimaryDestinationChange: () => {},
  handleSecondaryDestinationChange: () => {},
  selectedSecondaryDestination: {},
  setSelectedPrimaryDestination: () => {},
  selectedPrimaryDestination: {},

  editPage: false,
  detailPage: true,
};
// typechecking props for TicketInfo
TicketInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  handleTagChange: PropTypes.func,
  handleLocationChange: PropTypes.func,
  selectedTag: PropTypes.instanceOf(Object),
  selectedLocation: PropTypes.instanceOf(Object),
  selectedCurator: PropTypes.instanceOf(Object),
  setSelectedCurator: PropTypes.func,
  handleCuratorChange: PropTypes.func,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
  handlePrimaryDestinationChange: PropTypes.func,
  handleSecondaryDestinationChange: PropTypes.func,
  selectedSecondaryDestination: PropTypes.instanceOf(Object),
  setSelectedPrimaryDestination: PropTypes.func,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default TicketInfo;
