/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid2";
import PropTypes from "prop-types";
import { Formik } from "formik";
import MDBox from "components/MDBox";
import ItemsPicker from "components/AutoComplete/ItemsPicker";
import CopytoClipboard from "components/CopytoClipboard";
import { useDispatch, useSelector } from "react-redux";
import {
  generateItemsList,
  setWidgetLoading,
  getGeneratedItems,
  clearGeneratedItems,
} from "store/actions/itemAction";
import Loader from "components/Loader";
import MDButton from "components/MDButton";
import Danger from "components/Danger";
import WidgetDisplay from "./WidgetDisplay";
import FontSelector from "./FontSelector";
import TextAlignmentSelector from "./TextAlignmentSelector";

const GenerateTickets = ({ open, handleClose, itemsList, locationSlug, city, locationPartner }) => {
  const dispatch = useDispatch();

  const widget = useSelector((state) => state?.items?.widget);
  const widgetLoading = useSelector((state) => state?.items?.widgetLoading);
  const [textAlignment, setTextAlignment] = useState("left");
  const [fontFamily, setFontFamily] = useState(""); // State to manage the selected font family

  const [preview, setPreview] = useState(false);

  useEffect(() => {
    dispatch(clearGeneratedItems());
  }, []);

  useEffect(() => {
    if (locationSlug?.length > 0) dispatch(getGeneratedItems(locationSlug));
  }, [locationSlug]);

  const GenerateSchema = Yup.object().shape({
    tickets: Yup.array()
      .of(Yup.object()) // Assuming the items are strings, you can adjust the type accordingly
      .test(
        "is-two-or-four-items",
        "Must contain either 2 or 4 tickets",
        (value) => value.length === 2 || value.length === 4
      ),
  });

  return (
    <Formik
      initialValues={{
        tickets: [],
      }}
      enableReinitialize
      validationSchema={GenerateSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          dispatch(setWidgetLoading());
          setSubmitting(false);
          const itemsIds = values?.tickets
            .filter((item) => item && (item._id || item.value)) // Check for _id or value property
            .map((item) => item._id || item.value);
          dispatch(generateItemsList(itemsIds, fontFamily, textAlignment, locationSlug));
        }, 500);
      }}
    >
      {({ setFieldValue, values, submitForm, errors, touched, resetForm }) => (
        <Dialog
          open={open}
          keepMounted
          onClose={() => {
            handleClose();
          }}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-paper": {
              height: "auto",
              width: { sm: "100%", md: "680px" },
              minWidth: { md: "680px" },
            },
          }}
        >
          <DialogTitle>Add tickets list</DialogTitle>
          <DialogContent>
            <MDBox
              sx={{
                display: "flex",
              }}
            >
              <Grid container style={{ width: "100%" }}>
                <Grid item sx={6} style={{ width: "50%", marginTop: "10px" }}>
                  <FontSelector
                    setFontFamily={
                      locationPartner?.name === "Rotana Hotels"
                        ? "Arial"
                        : city === "Lake Garda"
                        ? "Inherit"
                        : setFontFamily
                    }
                  />
                </Grid>
                <Grid item size={{ xs: 6 }} style={{ width: "50%", marginTop: "10px" }}>
                  {" "}
                  <TextAlignmentSelector
                    alignment={textAlignment}
                    setAlignment={city === "Lake Garda" ? "left" : setTextAlignment}
                  />
                </Grid>
                <Grid item size={{ xs: 12 }}>
                  <MDBox sx={{ marginTop: "30px" }}>
                    <ItemsPicker
                      inWidgetDialog
                      items={{ name: "tickets", label: "Items" }}
                      itemsList={itemsList}
                      itemsV={
                        values?.tickets?.length > 0 && values?.tickets[0]?._id?.length > 0
                          ? values?.tickets?.map((item) => ({
                              label: item?.title,
                              value: item?._id,
                            }))
                          : values?.tickets
                      }
                      setFieldValue={setFieldValue}
                      city={city}
                    />
                    {errors?.tickets && touched?.tickets && <Danger validation={errors?.tickets} />}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            {widgetLoading ? (
              <Loader />
            ) : (
              widget?.length > 0 && (
                <>
                  <MDBox>
                    <MDButton
                      variant="text"
                      color="info"
                      onClick={() => setPreview(!preview)}
                      style={{ padding: 0 }}
                    >
                      {preview ? "HTML Code" : "Preview"}
                    </MDButton>
                  </MDBox>
                  <Grid container sx={{ width: "100%" }}>
                    <Grid item size={{ xs: 12 }}>
                      {preview ? (
                        <WidgetDisplay htmlString={widget} />
                      ) : (
                        // <code dangerouslySetInnerHTML={{ __html: widget }} />
                        <MDBox>
                          <div
                            style={{
                              marginBottom: 10,
                              padding: "12px",
                              border: "1px solid rgba(34, 45, 41, 0.4)",
                              borderRadius: "4px",
                            }}
                          >
                            <CopytoClipboard text={widget} top />
                          </div>
                        </MDBox>
                      )}
                    </Grid>
                  </Grid>
                </>
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                resetForm();
              }}
            >
              Cancel
            </Button>
            <Button onClick={submitForm}>{widget?.length > 0 ? "Re-Generate" : "Generate"}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
// Setting default props
GenerateTickets.defaultProps = {
  itemsList: [],
  locationSlug: "",
  city: "",
  locationPartner: "",
};
GenerateTickets.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  itemsList: PropTypes.instanceOf(Array),
  locationSlug: PropTypes.string,
  city: PropTypes.string,
  locationPartner: PropTypes.string,
};

export default GenerateTickets;
