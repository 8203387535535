/* eslint-disable no-underscore-dangle */
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Layout
import DashboardLayout from "layouts/DashboardLayout";
// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
// import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
// Material UI components
import Grid from "@mui/material/Grid2";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
// Redux Actions
import setAlert from "store/actions/alertActions";
import {
  getLocations,
  setLoading,
  clearErrors,
  getUserPartnerLocations,
  unpublish,
  publish,
} from "store/actions/locationAction";
import { getPdf, getCsv } from "store/actions/exportAction";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const AllLocations = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const [rowDataLoc, setRowDataLoc] = useState({});
  const [open, setOpen] = useState(false);

  const locations = useSelector((state) => state.locations.locations);
  const error = useSelector((state) => state.locations.error);
  const user = useSelector((state) => state.users.user);
  const loading = useSelector((state) => state.locations.loading);

  const handleUnpublish = (id) => {
    dispatch(setLoading());
    dispatch(unpublish(id));
  };

  const handlePublish = (id) => {
    dispatch(setLoading());
    dispatch(publish(id));
  };

  const columns = [
    "Name",
    "Partner",
    "Address",
    "City Name",
    "Number of Rooms",
    "Creation Date ",
    "Status",
  ];
  useEffect(() => {
    dispatch(setLoading());
    if (user.role === "partner") {
      dispatch(getUserPartnerLocations());
    } else if (user.role === "admin") dispatch(getLocations());
  }, [user?.role]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      clearErrors();
    }
  }, [error, setAlert]);

  const handleClose = () => {
    setOpen(false);
  };

  const actionsChildFn = (rowData) => {
    let actionsChildRole = {};
    actionsChildRole = {
      icon: rowData.status === "published" ? UnpublishedIcon : PublishedWithChangesIcon,
      tooltip: rowData.status === "published" ? "Unpublish" : "Publish",

      iconProps: {
        style: {
          marginLeft: "10px",
          fontSize: "25px",
          color: rowData.status === "published" ? "#f50057" : "green",
        },
      },
      onClick: (ww, rowDataId) => {
        setOpen(true);
        setRowDataLoc(rowDataId);
      },
    };
    return actionsChildRole;
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3}>
          <Grid item size={{ xs: 12 }}>
            {(user.role === "partner" || user.role === "admin") && (
              <MaterialTablePicker
                screen="LocationsList"
                title="Locations"
                searchField="locations"
                exportMenuField="locations"
                onRowClickURL={`/${user.role}/locations`}
                currentUrl="locations"
                tableRef={tableRef}
                columns={
                  user?.role === "partner"
                    ? columns.filter((column) => column !== "Partner")
                    : columns
                }
                data={locations}
                isLoading={loading}
                filteringOptions={false}
                addUrl="locations/new-location"
                actionsName="Location"
                actionsChild={actionsChildFn}
                editableRole={user.role === "admin"}
                actionsRole={user.role === "admin"}
                getCsv={() => {
                  if (user?.role === "partner") {
                    dispatch(
                      getCsv(
                        `stats/csv?limit=${
                          locations?.length
                        }&page=1&search=&sort=name&dir=asc&partner_id=${
                          user.partner._id || user.partner
                        }&fields=name,partner,geoLocationData.address,geoLocationData.city,numRooms,createdAt`,
                        "Locations"
                      )
                    );
                  } else if (user?.role === "admin") {
                    dispatch(
                      getCsv(
                        `stats/csv?limit=${locations?.length}&page=1&search=&sort=name&dir=asc&fields=name,partner,geoLocationData.address,geoLocationData.city,numRooms,createdAt`,
                        "Locations"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for CSV export.");
                  }
                }}
                getPdf={(value) => {
                  if (!value) {
                    // Handle the case where 'value' is undefined or empty
                  }

                  if (user?.role === "partner") {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf?limit=${
                          locations?.length
                        }&page=1&search=&sort=name&dir=asc&partner_id=${
                          user.partner._id || user.partner
                        }&fields=name,partner,geoLocationData.address,geoLocationData.city,numRooms,createdAt`,
                        "Locations"
                      )
                    );
                  } else {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf?limit=${locations?.length}&page=1&search=&sort=name&dir=asc&fields=name,partner,geoLocationData.address,geoLocationData.city,numRooms,createdAt`,
                        "Locations"
                      )
                    );
                  }
                }}
              />
            )}
          </Grid>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {rowDataLoc.status === "published" ? (
                <DialogTitle id="alert-dialog-title">Unpublish Location </DialogTitle>
              ) : (
                <DialogTitle id="alert-dialog-title">Publish Location </DialogTitle>
              )}

              <DialogContent>
                {rowDataLoc.status === "published" ? (
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to unpublish this location?
                  </DialogContentText>
                ) : (
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to publish this location?
                  </DialogContentText>
                )}
              </DialogContent>
              <DialogActions>
                <MDButton
                  onClick={() => {
                    if (rowDataLoc?.status === "published") {
                      handleUnpublish(rowDataLoc?._id);
                      handleClose();
                    } else {
                      handlePublish(rowDataLoc?._id);
                      handleClose();
                    }
                  }}
                >
                  Yes
                </MDButton>
                <MDButton onClick={handleClose} autoFocus>
                  No
                </MDButton>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
        <Footer />
      </DashboardLayout>
    </div>
  );
};

export default AllLocations;
