/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDButton from "components/MDButton";
import { Autocomplete, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MaterialTablePicker from "components/MaterialTablePicker";

import MDBox from "components/MDBox";
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Formik, Form } from "formik";

import {
  getCountryPartnerTrafficReports,
  getDroplistTrafficReports,
  setLoading,
} from "store/actions/reportActions";
import DynamicDateRange from "components/DynamicDateRange";
import {
  addYears,
  endOfDay,
  endOfYear,
  isSameDay,
  startOfDay,
  startOfYear,
  subDays,
  subYears,
} from "date-fns";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import Spinner from "components/Spinner";
import CountryReportPicker from "components/AutoComplete/ReportsPickers/CountryReportPicker";
import CityReportPicker from "components/AutoComplete/ReportsPickers/CityReportPicker";
import LocationReportPicker from "components/AutoComplete/ReportsPickers/LocationReportPicker";
import Danger from "components/Danger";
import { defaultStaticRanges } from "react-date-range";
import PdfTrafficReportByCountry from "./PdfTrafficReportByCountry";
import CsvTrafficReportByCountry from "./CsvTrafficReportsByCountry";
import initialValues from "./TrafficByCountryList/schemas/initialValues";
import validations from "./TrafficByCountryList/schemas/validations";

const TrafficByCountry = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();
  const formRef = useRef();

  const cities = useSelector((state) => state.reports.droplistTrafficReportCity);
  const locations = useSelector((state) => state.reports.droplistTrafficReportLocation);
  const trafficByCountry = useSelector((state) => state.reports.trafficReportsByCountry);
  const reports = useSelector((state) => state.reports.trafficReportsByCountry);

  const countries = useSelector((state) => state.reports.droplistTrafficReportCountry);
  const loading = useSelector((state) => state.reports.loading);
  const cityLoading = useSelector((state) => state.cities.loading);
  const locationLoading = useSelector((state) => state.cities.loading);
  const countryLoading = useSelector((state) => state.reports.loading);

  const handleValidation = () => validations[0];
  const currentValidation = handleValidation();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [trafficCountryRes, setTrafficCountryRes] = useState([]);
  const [hideDaterange, setHideDaterange] = useState(true);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [arr, setArr] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [generate, setGenerate] = useState(false);
  const [prevFilteredData, setPrevFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        7
      ),
      endDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        1
      ),
      key: "selection",
    },
  ]);

  const trafficReportArrayFn = () => {
    const arr2 = [];

    trafficByCountry.forEach((partner) => {
      partner.partnerResults[0].countryResults.forEach((city) => {
        arr2.push(city);
      });

      setArr(arr2);
    });
  };

  const filteredData = arr.reduce((acc, curr) => {
    const existingCity = acc.find((item) => item.cityName === curr.cityName);
    if (existingCity) {
      existingCity.cityResults.push(...curr.cityResults);
    } else {
      const newCity = JSON.parse(JSON.stringify(curr));
      acc.push(newCity);
    }
    return acc;
  }, []);

  const filteredStaticRanges = defaultStaticRanges.filter(
    (staticRange) => staticRange.label !== "Today"
  );

  const selectedRanges = [
    ...filteredStaticRanges,
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          7
        ),
        endDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          1
        ),
      }),
      isSelected(range) {
        return (
          isSameDay(range.startDate, range.endDate) &&
          isSameDay(range.startDate, startOfDay(subDays(new Date(), 6))) &&
          isSameDay(range.endDate, endOfDay(new Date()))
        );
      },
    },
    {
      label: "This Year",
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          1
        ),
      }),
      isSelected(range) {
        return (
          isSameDay(range.startDate, range.endDate) &&
          isSameDay(range.startDate, startOfYear(new Date())) &&
          isSameDay(range.endDate, endOfDay(new Date()))
        );
      },
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: startOfYear(addYears(new Date(), -1)),
        endDate: endOfYear(addYears(new Date(), -1)),
      }),
      isSelected(range) {
        return (
          isSameDay(range.startDate, range.endDate) &&
          isSameDay(range.startDate, startOfYear(subYears(new Date(), 1))) &&
          isSameDay(range.endDate, endOfYear(subYears(new Date(), 1)))
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getDroplistTrafficReports(selectedCountry, selectedCity, selectedLocation));
    // eslint-disable-next-line
  }, [selectedCountry, selectedCity, selectedLocation]);

  // this useEffect is used to display the prev data to new data
  useEffect(() => {
    // Check if filteredData has changed from the previous value
    if (JSON.stringify(filteredData) !== JSON.stringify(prevFilteredData)) {
      setPrevFilteredData(filteredData); // Update the previous value
      setTrafficCountryRes(filteredData);
    }
  }, [filteredData, prevFilteredData]);

  useEffect(() => {
    setTrafficCountryRes([]);
    setArr([]);
  }, [selectedCountry, selectedCity, selectedLocation]);

  useEffect(() => {
    if (filteredData && generate) {
      setTrafficCountryRes(filteredData);
    }
  }, [generate, filteredData.length]);

  useEffect(() => {
    if (reports?.length > 0) trafficReportArrayFn();
  }, [reports]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (values, actions) => {
    await sleep(1000);

    dispatch(setLoading());
    dispatch(
      getCountryPartnerTrafficReports({
        dateRange,
        selectedLocation,
        selectedCity,
        selectedCountry,
      })
    );
    // actions.setSubmitting(false);
    // actions.resetForm();
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);

    setAnchorEl(null);
  };

  const handleFileDownloadIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedOption(null);
  };

  const handleSubmit = (values, actions) => {
    if (!selectedCountry) {
      return;
    }
    submitForm(values, actions);
  };

  const handleReset = (values, actions) => {
    setArr([]);
    setSelectedCity(null);
    setSelectedCountry(null);
    setSelectedLocation(null);
    setDateRange([
      {
        startDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          7
        ),
        endDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          1
        ),
        key: "selection",
      },
    ]);
  };

  const handleDateChange = (date, event) => {
    if (date?.selection?.startDate && isSameDay(date.selection.startDate, date.selection.endDate)) {
      const newStartDate = date.selection.startDate;
      const newEndDate = dateRange[0].endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    }
  };

  const handleDownload = (option) => {
    if (option === "pdf") {
      return <PdfTrafficReportByCountry arr={filteredData} />;
    }
    if (option === "csv") {
      return <CsvTrafficReportByCountry arr={filteredData} />;
    }
    return null;
  };

  const handleCountryChange = (value) => {
    setGenerate(false);
    if (!value) {
      setSelectedCountry(null);
      setSelectedCity(null);
      setSelectedLocation(null);
    } else {
      setSelectedCountry(value);
    }
  };
  const handleCityChange = (value) => {
    setGenerate(false);
    if (!value) {
      setSelectedCity(null);
    } else {
      setSelectedCity(value);
    }
  };

  const handleLocationChange = (value) => {
    setGenerate(false);
    if (!value) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(value);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item size={{ xs: 12, lg: 8 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={handleSubmit}
          innerRef={(f) => {
            formRef.current = f;
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form autoComplete="off">
              <Grid container spacing={3}>
                <Grid item size={{ xs: 6 }}>
                  {" "}
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Country *
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <CountryReportPicker
                      countries={countries}
                      setFieldValue={setFieldValue}
                      country={selectedCountry}
                      handleChange={handleCountryChange}
                      setValCity={() => {}}
                    />
                  </MDBox>
                  {errors?.country && touched?.country && <Danger validation={errors?.country} />}
                </Grid>

                <Grid item size={{ xs: 6 }}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    City
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <CityReportPicker
                      cities={cities}
                      setFieldValue={setFieldValue}
                      city={selectedCity}
                      handleChange={handleCityChange}
                      setValCity={() => {}}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {" "}
                <Grid item mt={3} size={{ xs: 6 }}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Location
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <LocationReportPicker
                      locations={locations}
                      setFieldValue={setFieldValue}
                      location={selectedLocation}
                      handleChange={handleLocationChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item mt={3.5} size={{ xs: 6 }}>
                  <DynamicDateRange
                    defaultValue={dateRange}
                    selectedOption={dateRange}
                    selectedRanges={selectedRanges}
                    handleDateChange={handleDateChange}
                    hideDaterange={hideDaterange}
                    hideDateRangeFn={setHideDaterange}
                  />
                  <MDBox style={{ fontSize: "small" }}>
                    Selected Date Range: {dateRange[0]?.startDate.toDateString()} to{" "}
                    {dateRange[0].endDate.toDateString()}
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "right",
                  // marginRight: "45px",
                  marginTop: "20px",
                }}
              >
                <div style={{ marginTop: "20px" }}>
                  <MDButton
                    onClick={(val, actions) => {
                      if (Object.keys(errors).length === 0) {
                        setHideDaterange(true);
                        setGenerate(true);
                      }
                    }}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Generate Report
                  </MDButton>
                </div>
                <div style={{ textAlign: "right", marginTop: "20px", marginLeft: "8px" }}>
                  <MDButton
                    onClick={(val, actions) => {
                      handleReset(val, actions);
                      setFieldValue("city", null);
                      setFieldValue("location", null);
                      setFieldValue("country", null);
                      setFieldValue("dateRange", {});
                    }}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Reset Filters
                  </MDButton>
                </div>
              </MDBox>
              {selectedCountry && arr.length > 0 && trafficCountryRes?.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    height: "21px",
                    marginTop: "10px",
                    backgroundColor: "#d3d3d3",
                  }}
                />
              )}
              {trafficCountryRes.length > 0 &&
                // !hideDiv &&
                selectedCountry !== "" && (
                  <MDBox>
                    <h3
                      style={{
                        marginTop: 20,
                        padding: 0,
                      }}
                    >
                      <b style={{ color: "grey" }}> Country name:</b>{" "}
                      {filteredData[0]?.cityResults[0].country}{" "}
                    </h3>
                    <h6
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: "14px",
                        textTransform: "none",
                        fontWeight: "lighter",
                      }}
                    >
                      Traffic by country report
                    </h6>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          float: "right",
                          textAlign: "center",
                          marginRight: "75px",
                        }}
                      >
                        <div>
                          <FileDownloadIcon
                            style={{ cursor: "pointer", width: "30px", height: "30px" }}
                            onClick={handleFileDownloadIconClick}
                          >
                            <MoreVertIcon />
                          </FileDownloadIcon>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                          >
                            <MenuItem
                              onClick={() => {
                                handleOptionClick("pdf");
                              }}
                            >
                              Download as PDF
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleOptionClick("csv");
                              }}
                            >
                              Download as CSV
                            </MenuItem>
                          </Menu>
                        </div>
                      </span>
                    </div>
                  </MDBox>
                )}
              <Grid item size={{ xs: 12 }} mt={3}>
                {trafficCountryRes?.length > 0 &&
                  selectedCountry &&
                  trafficCountryRes?.map((val, i) => (
                    <MDBox key={val?.cityName}>
                      <span
                        style={{
                          marginRight: "30px",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                      >
                        City: <b style={{ color: "grey" }}>{val.cityName}</b>{" "}
                      </span>
                      <MaterialTablePicker
                        screen="traffic by country"
                        title="Traffic by country"
                        searchField="traffic by country"
                        exportMenuField="trafficByCountry"
                        currentUrl="locations"
                        tableRef={tableRef}
                        columns={[
                          "Location",
                          "Total views",
                          "Total users",
                          "New users",
                          "Number of sign ups",
                          "Number of logins",
                          "Revenue",
                          "Tickets sold",
                          "Conversion ratio (purchases/users)",
                        ]}
                        isRowClickable={false}
                        data={val?.cityResults}
                        filteringOptions={false}
                        isSearchable={false}
                        isExportable={false}
                        actionsName="Traffic by country"
                      />
                    </MDBox>
                  ))}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <Footer />
      <Spinner loading={loading} />
      {selectedOption && handleDownload(selectedOption)}
    </DashboardLayout>
  );
};

export default TrafficByCountry;
