/* eslint-disable no-underscore-dangle */

import PropTypes from "prop-types";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveRecommendationFilter } from "store/actions/filterActions";
import ViewEditActions from "components/DetailList/ViewEditActions";
import RecommendationsPicker from "components/AsyncSelect/RecommendationsPicker";

const ByRecommendation = ({ locationId, filters, destination }) => {
  const dispatch = useDispatch();

  const [detail, setDetail] = useState(true);
  const [selectedRecommendations, setSelectedRecommendations] = useState([]);

  useEffect(() => {
    if (filters?.recommendations?.length > 0) {
      setSelectedRecommendations(filters.recommendations);
    }
    if (filters?.recommendations?.length === 0) setSelectedRecommendations([]);
  }, [filters]);

  const handleRecommendationChange = (value) => {
    selectedRecommendations?.map((val) => ({ value: val, label: val }));
    if (value) {
      setSelectedRecommendations(value);
    } else {
      setSelectedRecommendations([]);
    }
  };

  const handleSave = (event) => {
    event.preventDefault(); // Prevent form submission
    const newState = selectedRecommendations.map((val) => val.value);
    dispatch(saveRecommendationFilter(newState, locationId));
    setDetail(true);
  };

  return (
    <Card id="recommendation" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Filter by Recommendation</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item size={{ xs: 12 }}>
            <MDBox sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="body2">
                Choose the Recommendations you don&#39;t want your visitors to see:
              </MDTypography>

              <ViewEditActions
                detailPage={detail}
                handleEdit={() => setDetail(false)}
                handleClose={() => {
                  setDetail(true);
                  setSelectedRecommendations(filters.recommendations);
                }}
                handleSave={handleSave}
              />
            </MDBox>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="end"
              sx={{
                width: "100%",
              }}
            >
              <Grid item size={{ xs: 12 }}>
                {detail ? (
                  <MDBox style={{ display: "flex", flexDirection: "column" }}>
                    {selectedRecommendations?.length > 0 &&
                      selectedRecommendations?.map((item) => (
                        <MDTypography
                          key={item?._id || item?.value}
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          - {item?.title || item?.label}
                        </MDTypography>
                      ))}
                  </MDBox>
                ) : (
                  <RecommendationsPicker
                    byRecPage
                    destination={destination}
                    locationId={locationId}
                    recommendations={{ name: "recommendation", Label: "Recommendation" }}
                    recommendationsV={
                      selectedRecommendations?.length > 0 &&
                      selectedRecommendations[0]?._id?.length > 0
                        ? selectedRecommendations?.map((item) => ({
                            label: item?.title,
                            value: item?._id,
                          }))
                        : selectedRecommendations
                    }
                    setFieldValue={() => {}}
                    handleChange={handleRecommendationChange}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

// Setting default props
ByRecommendation.defaultProps = {
  locationId: "",
  filters: {},
  destination: "",
};

// typechecking props for ByRecommendation
ByRecommendation.propTypes = {
  locationId: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
  destination: PropTypes.string,
};
export default ByRecommendation;
