/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */

import { useEffect, useRef, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import { scrollToTop, useHandleError } from "components/helper";
// NewUser page components
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import setAlert from "store/actions/alertActions";
import { saveMenuType, addMenu, clearErrors } from "store/actions/menuActions";

// Components
import Review from "./components/Review";
import form from "./schema/form";
import validations from "./schema/validations";
import initialValues from "./schema/initialValues";
import MenuInfo from "./components/MenuInfo";
import MenuFilter from "./components/MenuFilter";

function getSteps() {
  return ["Menu Info", "Filters", "Review"];
}

function getStepContent(
  stepIndex,
  formData,
  selectedDestinations,
  handleDestinationsChange,
  selectedLocations,
  setSelectedLocations,
  setSelectedDestinations,
  handleLocationsChange,
  selectedTags,
  setSelectedTags,
  handleTagsChange,
  selectedTickets,
  setSelectedTickets,
  handleTicketChange
) {
  switch (stepIndex) {
    case 0:
      return (
        <MenuInfo
          formData={formData}
          selectedDestinations={selectedDestinations}
          handleDestinationsChange={handleDestinationsChange}
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
          setSelectedDestinations={setSelectedDestinations}
          handleLocationsChange={handleLocationsChange}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          handleTagsChange={handleTagsChange}
          selectedTickets={selectedTickets}
          setSelectedTickets={setSelectedTickets}
          handleTicketChange={handleTicketChange}
        />
      );
    case 1:
      return <MenuFilter formData={formData} />;
    case 2:
      return <Review formData={formData} />;
    default:
      return null;
  }
}

const NewMenu = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);

  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [initialState, setInitialState] = useState(initialValues);

  const menuType = useSelector((state) => state.menus.menuType);
  const error = useSelector((state) => state.menus.error);

  const handleDestinationsChange = (val) => {
    if (val) {
      setSelectedDestinations(val);
      dispatch(saveMenuType({ type: "destinations", destinationSearch: val, locationSearch: [] }));
    }
  };

  const handleLocationsChange = (val) => {
    if (val) {
      setSelectedLocations(val);
      dispatch(saveMenuType({ type: "location", locationSearch: val, destinationSearch: [] }));
    }
  };

  const handleTagsChange = (val) => {
    if (val) {
      setSelectedTags(val);
    }
  };

  const handleTicketChange = (val) => {
    if (val) {
      setSelectedTickets(val);
    }
  };

  const { formId, formField } = form;
  const isLastStep = activeStep === steps.length - 1;

  // useEffect
  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    setSteps(getSteps());
  }, []);

  useEffect(() => {
    const manipulated = {};
    manipulated.title = "";
    manipulated.logo = "";
    manipulated.images = "";
    manipulated.option = "Recommendation";
    manipulated.filterBy = "tags";
    manipulated.type = menuType?.type;
    manipulated.destinations = menuType?.destinationSearch || [];
    manipulated.locations = menuType?.locationSearch || [];
    manipulated.tags = [];
    manipulated.url = "";
    manipulated.hidden = false;
    manipulated.top = false;
    manipulated.expandable = false;
    manipulated.filters = [
      "category",
      "distance",
      "language",
      "cuisines",
      "price",
      "features",
      "opening hours",
      "duration",
    ];
    setSelectedDestinations(menuType?.destinationSearch);
    setSelectedLocations(menuType?.locationSearch);
    setSelectedTags([]);
    setSelectedTickets([]);
    setInitialState(manipulated);
  }, []);

  useEffect(() => {
    if (menuType?.destinationSearch?.length > 0) {
      setSelectedDestinations(menuType?.destinationSearch);
    }
  }, [menuType]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTop(); // Scroll to the top
  };

  const submitForm = async (values, actions) => {
    const updatedValues = { ...values };
    if (updatedValues && updatedValues?.logo) {
      delete updatedValues?.logo;
    }

    dispatch(addMenu(updatedValues, values?.logo));

    await sleep(1000);

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if ((isLastStep && values.role === "admin") || isLastStep) {
      submitForm(values, actions);
      navigate("/admin/menus", { state: { menuType: values?.type } });
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      scrollToTop(); // Scroll to the top
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={
                steps[activeStep] === "Review" ? undefined : validations[activeStep]
              }
              onSubmit={handleSubmit}
              innerRef={(f) => {
                // setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          selectedDestinations,
                          handleDestinationsChange,
                          selectedLocations,
                          setSelectedLocations,
                          setSelectedDestinations,
                          handleLocationsChange,
                          selectedTags,
                          setSelectedTags,
                          handleTagsChange,
                          selectedTickets,
                          setSelectedTickets,
                          handleTicketChange
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() =>
                                navigate("/admin/menus", { state: { menuType: values?.type } })
                              }
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "add" : "next"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewMenu;
