/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";

// Material UI components
import Grid from "@mui/material/Grid2";

// Custom Components
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
import { createRef, useEffect } from "react";
import { clearErrors } from "store/actions/experienceActions";
import setAlert from "store/actions/alertActions";
import { useDispatch, useSelector } from "react-redux";
import { getPdf, getCsv } from "store/actions/exportAction";
import ItemsTable from "pages/reports/productionReports/all-productionReports/ProductionReportsList/ItemsTable";
import { getConciergeOrders } from "store/actions/statsActions";
import { useHandleError } from "components/helper";

const ConciergeOrdersInDetails = ({ user }) => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const error = useSelector((state) => state.experiences.error);
  const loading = useSelector((state) => state.stats.loading);
  const conciergeOrders = useSelector((state) => state.stats.conciergeOrders);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (user) {
      dispatch(getConciergeOrders(user));
    }
  }, [user]);

  return (
    <MDBox>
      <Grid>
        <Grid item size={{ xs: 12 }}>
          <MaterialTablePicker
            screen="ConciergeOrdersInDetails"
            title="Orders"
            searchField="conciergeOrders"
            currentUrl="conciergeOrders"
            tableRef={tableRef}
            isActions={false}
            columns={[
              "Invoice Number",
              "Purchase Date ",
              "Order Total ",
              "Commission ($)",
              "Promocode",
            ]}
            isRowClickable={false}
            data={conciergeOrders?.orders}
            // eslint-disable-next-line react/no-unstable-nested-components
            detailPanel={(rowData) => (
              <MDBox sx={{ ml: 6, mr: 4 }}>
                <ItemsTable items={rowData.rowData.items} page="concierge" />
              </MDBox>
            )}
            tableInside
            isFreeDownloadAction
            isLoading={loading}
            filteringOptions={false}
            isExportable={false}
            actionsRole
            actionsName="conciergeOrders"
            getPdf={(value) => {
              if (value) {
                dispatch(getPdf(value, "conciergeOrders/createPdf?pdf=true", "Concierge Orders"));
              } else {
                // Handle the case where 'value' is undefined or empty
                console.error("Error: No data for PDF export.");
              }
            }}
            getCsv={(value) => {
              if (value) {
                dispatch(getCsv(`${process.env.REACT_APP_API}/conciergeOrders/csv?csv=true`));
              } else {
                // Handle the case where 'value' is undefined or empty
                console.error("Error: No data for PDF export.");
              }
            }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};
// Setting default props
ConciergeOrdersInDetails.defaultProps = { user: "" };
// typechecking props for ConciergeOrdersInDetails
ConciergeOrdersInDetails.propTypes = {
  //   experienceId: PropTypes.string.isRequired,
  user: PropTypes.string,
};

export default ConciergeOrdersInDetails;
