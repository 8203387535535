import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
// import Grid from "@mui/material/Grid2";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Icon from "@mui/material/Icon";
// import { Link } from "react-router-dom";
// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

const DynamicTabs = ({ detailTabs, tabValue, handleSetTabValue, disabledTab, userRole }) => {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <AppBar position="static">
      <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
        {detailTabs.map((item, i) => (
          <Tab
            disabled={
              (disabledTab && item?.id === 6) ||
              (item.title === "Viator tickets" && userRole === "partner")
            }
            key={item?.id || i}
            icon={
              item.iconComponent && (
                <item.iconComponent fontSize="small" sx={{ mr: "0px!important" }} />
              )
            }
            label={item?.title || item}
            iconPosition="start"
            sx={{
              padding: "4px 12px",
              fontSize: "10px",
              display: "flex",
              flexDirection: "column",
              color:
                (disabledTab && item?.id === 6) ||
                (item.title === "Viator tickets" && userRole === "partner")
                  ? "grey !important"
                  : "inherit",
            }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

// Setting default props for the DynamicTabs
DynamicTabs.defaultProps = {
  detailTabs: [],
  disabledTab: false,
  userRole: "",
};

// Typechecking props for the DynamicTabs
DynamicTabs.propTypes = {
  detailTabs: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disabledTab: PropTypes.bool,
  userRole: PropTypes.string,
  tabValue: PropTypes.number.isRequired,
  handleSetTabValue: PropTypes.func.isRequired,
};

export default DynamicTabs;
