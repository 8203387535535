// Libraries
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// Custom components
import breakpoints from "assets/theme/base/breakpoints";
import DynamicTabs from "components/DynamicTabs";
import ImagePicker from "components/AutoComplete/ImagePicker";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SVGPicker from "components/AutoComplete/SVGPicker";
import { dateFormatWithDash } from "components/helper";
import { Button } from "@mui/material";

const DynamicHeader = ({
  children,
  user,
  image,
  imgDetailPage,
  detailTabs,
  tabValue,
  handleSetTabValue,
  imageType,
  noImg,
  svgPick,
  handleNavigation,
  isMyProfile,
  disabledTab,
}) => {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <MDBox position="relative" mb={5}>
      {!isMyProfile && (
        <MDBox>
          <Grid item sx={{ display: "flex", alignItems: "left" }}>
            <Button
              onClick={handleNavigation}
              color="dark"
              sx={{ textTransform: "none" }}
              style={{ paddingLeft: "0" }}
            >
              <ArrowBackIosNewIcon />
              <MDTypography
                variant="body2"
                sx={{ fontSize: "0.9rem", fontWeight: "bold", marginLeft: "4px" }}
              >
                Back
              </MDTypography>
            </Button>
          </Grid>
        </MDBox>
      )}

      <Card id="profile">
        <MDBox p={2}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item size={{ xs: 10 }} sx={{ display: "flex", alignItems: "center" }}>
              {!noImg && !svgPick && (
                <ImagePicker currentImg={image} edit={!imgDetailPage} imageType={imageType} />
              )}
              {!noImg && svgPick && <SVGPicker currentImg={image} edit={!imgDetailPage} />}
              <MDBox width="100%" height="100%" mt={0.5} ml={imgDetailPage ? 2 : 0} lineHeight={1}>
                {user && (user?.name || user?.title || user?.destinationName) && (
                  <>
                    <MDTypography
                      variant="h5"
                      fontWeight="medium"
                      sx={{
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {user?.name || user?.title || user?.destinationName}
                    </MDTypography>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ marginRight: "50px" }}>
                        {user?.createdAt && (
                          <MDTypography variant="h6" fontWeight="light" fontSize="small">
                            Creation Date: {dateFormatWithDash(user.createdAt)}
                          </MDTypography>
                        )}
                        {user?.createdBy && typeof user.createdBy.name === "string" && (
                          <MDTypography variant="h6" fontWeight="light" fontSize="small">
                            Created By: {user.createdBy?.name}
                          </MDTypography>
                        )}
                      </div>
                      <div>
                        {user?.updatedAt && (
                          <MDTypography variant="h6" fontWeight="light" fontSize="small">
                            Last Updated: {dateFormatWithDash(user.updatedAt)}
                          </MDTypography>
                        )}
                        {user?.updatedBy?.name && typeof user.updatedBy.name === "string" && (
                          <MDTypography variant="h6" fontWeight="light" fontSize="small">
                            Updated By: {user.updatedBy.name}
                          </MDTypography>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </MDBox>
            </Grid>
            <Grid item textAlign="end" alignItems="center" style={{ display: "flex" }}>
              {children}
            </Grid>
            <Grid item size={{ xs: 12 }} textAlign="end" alignItems="end">
              {detailTabs?.length > 0 ? (
                <Grid item size={{ xs: 12 }} sx={{ ml: "auto", mt: 2 }}>
                  <DynamicTabs
                    detailTabs={detailTabs}
                    tabValue={tabValue}
                    handleSetTabValue={handleSetTabValue}
                    disabledTab={disabledTab}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
};

// Setting default props for the DynamicHeader
DynamicHeader.defaultProps = {
  children: "",
  detailTabs: [],
  imgDetailPage: false,
  tabValue: 0,
  image: undefined,
  user: {},
  handleSetTabValue: () => {},
  handleNavigation: () => {},
  imageType: "",
  noImg: false,
  svgPick: false,
  isMyProfile: false,
  disabledTab: false,
};

// Typechecking props for the DynamicHeader
DynamicHeader.propTypes = {
  children: PropTypes.node,
  tabValue: PropTypes.number,
  image: PropTypes.string,
  user: PropTypes.instanceOf(Object),
  detailTabs: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  imgDetailPage: PropTypes.bool,
  handleSetTabValue: PropTypes.func,
  handleNavigation: PropTypes.func,
  imageType: PropTypes.string,
  noImg: PropTypes.bool,
  svgPick: PropTypes.bool,
  isMyProfile: PropTypes.bool,
  disabledTab: PropTypes.bool,
};

export default DynamicHeader;
